import React from "react";

const HeaderAdminLogin = () => {
  return (
    <>
      <main className="header mt-5 pt-1">
        <div className="container">
          <div className="row mt-4">
            <div className="col-12 d-flex justify-content-center flex-column align-items-center">
              <div className="logo_img">
                <img src="/images/Logo.png" alt="Loading..." loading="lazy" />
              </div>
              <div className="title mt-4 text-center">
                <h4>Admin Dashboard</h4>
              </div>
              <div className="para text-center">
                <p>With admin panel you can access user data and all process</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default HeaderAdminLogin;
