import React, { memo } from "react";
import moment from "moment";

const Status = ({ date, delivered, packed, shipped, UpdateStatus }) => {
  return (
    <div className="col-lg-8 col-12 status_details mt-lg-0 mt-3">
      <div className="row d-flex justify-content-center">
        <div className="col-12">
          <div className=""></div>
        </div>
        <div className="col-12">
          <ul id="progressbar" className="progressbar text-center">
            <li className="active step0">
              <p>Order Processed</p>
              <img
                className="icon"
                src="https://i.imgur.com/9nnc9Et.png"
                alt="loading..."
              />
              <span>{moment(date).format("MMM Do YY")}</span>
            </li>
            <li
              className={shipped?.value !== 0 ? "active step0" : "step0"}
              onClick={() => UpdateStatus("shipped", shipped?.value)}
            >
              <p>Order Shipped</p>
              <img
                className="icon"
                src="https://i.imgur.com/u1AzR7w.png"
                alt="loading..."
              />
              {shipped?.value !== 0 && (
                <span>{moment(shipped?.date).format("MMM Do YY")}</span>
              )}
            </li>
            <li
              className={packed?.value !== 0 ? "active step0" : "step0"}
              onClick={() => UpdateStatus("packed", packed?.value)}
            >
              <p>Out For Delivery</p>
              <img
                className="icon"
                src="https://i.imgur.com/TkPm63y.png"
                alt="loading..."
              />
              {packed?.value !== 0 && (
                <span>{moment(packed?.date).format("MMM Do YY")}</span>
              )}
            </li>
            <li
              className={delivered?.value !== 0 ? "active step0" : "step0"}
              onClick={() => UpdateStatus("delivered", delivered?.value)}
            >
              <p>Delivered</p>
              <img
                className="icon"
                src="https://i.imgur.com/HdsziHP.png"
                alt="loading..."
              />
              {delivered?.value !== 0 && (
                <span>{moment(delivered?.date).format("MMM Do YY")}</span>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default memo(Status);
