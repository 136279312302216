import styled from "styled-components";

const DashboardWrapper = styled.div`
  .Dashboard {
    .details {
      p {
        font-size: 1.8rem;
      }

      span {
        color: ${(props) => props.theme.BodySecondary};
      }
    }

    .img_section {
      img {
        max-width: 100%;
      }
    }
  }

  @media only screen and (max-width: 990px) {
    .Dashboard {
      .details {
        p {
          font-size: 1.5rem;
        }

        span {
          color: ${(props) => props.theme.BodySecondary};
        }
      }
    }
  }
`;

export default DashboardWrapper;
