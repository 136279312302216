import styled from "styled-components";

const HeaderWrapper = styled.div`
  .LeftPanel_Main_top {
    .img_section {
      height: 71.5px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 3px 0px;

      .logo_img {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: auto;
          height: 3rem;
        }
      }

      .close_div {
        display: none;
        cursor: pointer;

        .close_icon {
          color: ${(props) => props.theme.BodyPrimary};
          font-size: 1.4rem;
        }
      }
    }
  }

  .LeftPanel_Main_bottom {
    .navbar_List {
      .Nav_List {
        padding-top: 25px;
        padding-bottom: 100px;
        height: 100vh;
        overflow-y: scroll;

        ::-webkit-scrollbar {
          height: 5px;
          width: 5px;
          background-color: transparent;
        }

        li {
          list-style-type: none;
          cursor: pointer;
          transition: all 0.3s;
          border-radius: 20px;

          .noActive,
          .active {
            font-weight: 500;
            text-decoration: none;
            display: inline-block;
            width: 100%;
            padding: 0.8rem 1rem 0.8rem 1.2rem;
            span {
              padding-left: 12px;
              font-size: 1rem;
            }

            .list_icon {
              height: 1.2rem;
              width: auto;
            }
          }

          .noActive {
            color: ${(props) => props.theme.BodySecondary};
          }

          .active {
            color: ${(props) => props.theme.BodyBrand};
          }

          .dropdown_list {
            padding: 0.8rem 1rem 0.8rem 1.2rem;
            display: inline-block;
            text-decoration: none;
            width: 100%;
            color: ${(props) => props.theme.BodySecondary};

            .dropdown_list_icon {
              height: 1.2rem;
              width: auto;
            }

            span {
              padding-left: 12px;
              font-size: 1rem;
              font-weight: 500;
              text-decoration: none;
            }

            .collapse_btn {
              width: 100%;
              .collapse_icon {
                font-size: 1.2rem;
              }
              .show {
                display: block;
              }
              .hide {
                display: none;
              }
            }

            &:hover {
              color: ${(props) => props.theme.BodyBrand};
            }
          }

          .collapse {
            .collapse_nav_list {
              .noActive,
              .active {
                font-weight: 500;
                text-decoration: none;
                display: inline-block;
                width: 100%;
                padding: 0.8rem 1rem 0.8rem 1.2rem;
                span {
                  padding-left: 12px;
                  font-size: 0.97rem;
                }
                .list_icon {
                  height: 1rem;
                  width: auto;
                }
              }

              .noActive {
                color: ${(props) => props.theme.BodySecondary};
              }

              .noActive:hover {
                color: ${(props) => props.theme.BodyBrand};
              }

              .active {
                color: ${(props) => props.theme.BodyBrand};
              }
            }
          }
        }

        li:hover > a {
          color: ${(props) => props.theme.BodyBrand};
        }
      }
    }
  }

  .navbar_component {
    background: transparent !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 3px 0px;
    width: 100%;
    z-index: 800;
    padding: 0.4rem 0rem;
    height: 4.55rem;

    nav {
      justify-content: inherit !important;

      .navbar-brand {
        flex-grow: 0 !important;
        display: none;

        img {
          height: auto;
          width: 3rem;
        }
      }

      .navbar-icon {
        color: ${(props) => props.theme.BodyPrimary};
        display: none;
        font-size: 1.6rem;
        cursor: pointer;
      }

      .left_ui_block {
        display: flex;

        .nav_list {
          display: flex;
          align-items: center;
          padding: 0px 10px;

          .btn-group {
            display: flex;
            flex-direction: column;

            .dropdown_btn {
              background: transparent;
              border: none;
              outline: none;
              padding: 0.5rem 1rem;
              border-radius: 10px;
              transition: all 0.3s;

              .bottom_icon {
                color: ${(props) => props.theme.BodyBrand};
                font-size: 1.2rem;
              }
            }

            .dropdown-menu {
              position: absolute !important;
              background: ${(props) => props.theme.BodyNeutral};
              margin: 0.8rem 1.3rem 0;
              padding: 0rem 0rem;
              border-radius: 0.4rem;
              border: none;
              overflow: hidden;
              transform-origin: top center;
              transform: scale(1, 0);
              transition: all 0.2s;
              display: block;
              opacity: 1;
              box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
                rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

              .dropdown-item {
                padding: 1rem 2.4rem;
                color: white;
                transition: all 0.2s;

                .profile_icon {
                  font-size: 1.2rem;
                  color: ${(props) => props.theme.BodySecondary};
                }

                span {
                  padding-left: 5px;
                  font-size: 0.99rem;
                  color: ${(props) => props.theme.BodySecondary};
                  font-weight: 500;
                  text-decoration: none;
                }

                &:hover {
                  background: ${(props) => props.theme.BodyBrand};
                }

                &:focus {
                  background: ${(props) => props.theme.BodyBrand};
                }

                &:hover > span,
                &:hover > .profile_icon {
                  color: ${(props) => props.theme.BodyNeutral};
                }
              }
            }

            &.show {
              .dropdown-menu {
                transform: scale(1);
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    .navbar_component {
      padding: 0rem 1rem;

      nav {
        .navbar-brand {
          display: flex;

          img {
            height: 3rem;
            width: auto;
          }
        }

        .navbar-icon {
          display: block;
        }

        button {
          display: none;
        }
      }
    }

    .LeftPanel_Main_top {
      .img_section {
        .logo_img {
          justify-content: start;
        }

        .close_div {
          display: block;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .navbar_component {
      nav {
        .left_ui_block {
          .nav_list {
            .btn-group {
              .dropdown_btn {
                padding: 0.5rem 0rem;
              }
              .dropdown-menu {
                margin: 0.8rem 0 0;
              }
            }
          }
        }
      }
    }
  }
`;

export default HeaderWrapper;
