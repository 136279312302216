import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import TitleToggle from "components/globalComponents/TitleToggle";
import Button from "Layout/Button";
import TableWrapper from "styles/global/table.style";
import { getAllPinCodeFun } from "utils/getMethodFunction";
import DataLoader from "components/globalComponents/Loaders/DataLoader";
import Error from "components/globalComponents/Error";
import { DeletePinCodeFun } from "utils/postMethodFunction";

const DeletePinCode = () => {
  const dispatch = useDispatch();
  const [PinCodeList, setPinCodeList] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [Status, setStatus] = useState();

  useEffect(() => {
    const callApiFun = async () => {
      setLoading(true);
      const { PinCodes, status } = await getAllPinCodeFun(setLoading);
      if (PinCodes) {
        setPinCodeList(PinCodes);
      }
      if (status) {
        setStatus(status);
      }
    };
    callApiFun();

    return () => {
      setPinCodeList([]);
      setStatus();
    };
  }, []);

  if (Loading) {
    return <DataLoader />;
  } else if (Status) {
    return <Error status={Status} />;
  }

  return (
    <>
      <TitleToggle Title="PinCode" />
      <div className="RightPanel">
        <TableWrapper>
          <div className="container Delete">
            <div className="row d-flex justify-content-center table_section">
              <div className="col-lg-7 col-12">
                <div className="table_card">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>PinCode</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {PinCodeList &&
                        PinCodeList?.map((list) => {
                          return (
                            <tr key={list._id}>
                              <td>
                                <p>{list.pincode}</p>
                              </td>
                              <td>
                                <Button
                                  active={1}
                                  br="5px"
                                  p="0.5rem 0.5rem"
                                  id="btn"
                                  onClick={() =>
                                    dispatch(
                                      DeletePinCodeFun(
                                        list.pincode,
                                        PinCodeList,
                                        setPinCodeList
                                      )
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </TableWrapper>
      </div>
    </>
  );
};

export default DeletePinCode;
