import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import TitleToggle from "components/globalComponents/TitleToggle";
import Button from "Layout/Button";
import Select from "Layout/Form/Select";
import TableWrapper from "styles/global/table.style";
import { getAllTagFun } from "utils/postMethodFunction";
import DataLoader from "components/globalComponents/Loaders/DataLoader";
import Error from "components/globalComponents/Error";
import { DeleteTagFun } from "utils/postMethodFunction";
import { TagTypeOptions } from "Layout/FormElement/Options";

const DeleteTag = () => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("author");
  const [Loading, setLoading] = useState(false);
  const [Tag, setTag] = useState({
    List: [],
    status: null,
  });

  useEffect(() => {
    const asyncCall = async () => {
      if (Tag?.List?.length === 0) {
        setLoading(true);
      }
      await getAllTagFun(selected, setLoading, setTag);
    };
    asyncCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  if (Loading) {
    return <DataLoader />;
  } else if (Tag?.status) {
    return <Error status={Tag?.status} />;
  }

  return (
    <>
      <TitleToggle Title="Upload Tag" />
      <div className="RightPanel">
        <TableWrapper>
          <div className="container Delete">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-9 col-12">
                <div className="row d-flex justify-content-end">
                  <div className="col-lg-4 col-12">
                    <Select
                      name="type"
                      value={selected}
                      onChange={(e) => setSelected(e.target.value)}
                      p="0.5rem"
                      br="8px"
                    >
                      <option value="" defaultValue="selected" disabled hidden>
                        Select tag
                      </option>
                      {TagTypeOptions.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center table_section mt-4">
              <div className="col-lg-9 col-12">
                <div className="table_card">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Tag?.List?.length > 0 &&
                        Tag?.List?.map((list) => {
                          return (
                            <tr key={list._id}>
                              <td>
                                <span>{list.value}</span>
                              </td>
                              <td>
                                <Button
                                  active={1}
                                  br="5px"
                                  p="0.5rem 1rem"
                                  id="btn"
                                  onClick={() =>
                                    dispatch(
                                      DeleteTagFun(list._id, Tag?.List, setTag)
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </TableWrapper>
      </div>
    </>
  );
};

export default DeleteTag;
