import styled from "styled-components";

const TitleToggleWrapper = styled.div`
  .PageTitle {
    padding: 1.5rem 2rem;

    p {
      font-size: 1.35rem;
      font-weight: 500;
    }
  }

  @media only screen and (max-width: 600px) {
    .PageTitle {
      padding: 1rem 1rem;

      p {
        font-size: 1.3rem;
      }
    }
  }
`;

export default TitleToggleWrapper;
