import styled from "styled-components";

const InputWrapper = styled.div`
  input {
    border: 1px solid ${(props) => props.theme.borderMain};
    outline: none;
    width: 100% !important;
    background: transparent;
    padding: 0.5rem 1.2rem;
    border-radius: 8px;
    color: ${(props) => props.theme.BodySecondary};

    &::placeholder {
      color: ${(props) => props.theme.BodySecondary};
    }
  }
`;

export default InputWrapper;
