import styled from "styled-components";

const SelectWrapper = styled.select`
  border: 1px solid ${(props) => props.theme.borderMain};
  outline: none;
  width: 100% !important;
  background: transparent;
  padding: ${(props) => props.p};
  text-indent: 10px;
  border-radius: ${(props) => props.br};
  font-size: ${(props) => props.size};
  color: ${(props) => props.theme.BodySecondary};

  option {
    font-size: ${(props) => props.size};
  }
`;

export default SelectWrapper;
