import React from "react";
import { useNavigate } from "react-router-dom";
import ErrorWrapper from "./Error.style";
import Button from "Layout/Button";
import { useAuth } from "middleware/AuthProvider";

const Error = ({ ...props }) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  return (
    <ErrorWrapper>
      <div className={isAuthenticated ? "RightPanel Error_Page" : "Error_Page"}>
        <div className="container Error">
          <div className="row d-flex justify-content-center">
            <div className="col-12 d-flex justify-content-center">
              {props.status === 404 && (
                <img
                  src="/images/Status/404.png"
                  alt="Loading..."
                  loading="lazy"
                />
              )}
              {props.status === 500 && (
                <img
                  src="/images/Status/500.png"
                  alt="Loading..."
                  loading="lazy"
                />
              )}
            </div>
            <div className="col-lg-5 col-md-6 col-12 d-flex justify-content-center mt-3">
              <Button
                active={1}
                br="10px"
                p="0.5rem"
                id="btn"
                onClick={() => navigate(-1)}
              >
                go back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ErrorWrapper>
  );
};

export default Error;
