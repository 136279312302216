import React from "react";
import TitleToggleWrapper from "./TitleToggle.style";

const TitleToggle = ({ Title }) => {
  return (
    <TitleToggleWrapper>
      <div className="RightPanel">
        <div className="container PageTitle">
          <div className="row">
            <div className="col-12">
              <div className="PageTitle_section">
                <p>{Title}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TitleToggleWrapper>
  );
};

export default TitleToggle;
