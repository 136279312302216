import React, { useState, createContext, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ScreenLoader from "components/globalComponents/Loaders/ScreenLoader";
import { setSnackbar } from "redux/actions/global";
import { getCookies } from "utils";
import api from "api";
import axios from "axios";
import Cookie from "js-cookie";
import { ErrorMessageFun } from "utils";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [AdminData, setAdminData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const LoginFun = (Login, setLoading) => {
    return async (dispatch) => {
      try {
        const { email, password } = Login;

        if (email && password) {
          setLoading(true);
          const response = await axios.post(api.login, {
            email,
            password,
          });

          if (response.status === 200) {
            setLoading(false);
            setAdminData(response.data.data);
            Cookie.set(
              "BhavyaPublication_Secret_Token",
              response.data.data.token
            );
            dispatch(setSnackbar(true, "success", response.data.message));
            navigate("/");
          }
        } else {
          setLoading(false);
          dispatch(setSnackbar(true, "info", "All field required"));
        }
      } catch (error) {
        setLoading(false);
        ErrorMessageFun(error, dispatch, setSnackbar);
      }
    };
  };

  const Logout = () => {
    return async (dispatch) => {
      const Token = getCookies();
      if (Token) {
        Cookie.remove("BhavyaPublication_Secret_Token");
        dispatch(setSnackbar(true, "success", "Logged out successfully"));
        setAdminData();
        navigate("/");
      }
    };
  };

  useEffect(() => {
    async function loadAdminFromCookies() {
      const Token = getCookies();
      if (Token) {
        try {
          setIsLoading(true);

          const response = await axios.post(
            api.profile,
            {},
            {
              headers: {
                Authorization: Token,
              },
            }
          );

          if (response.status === 200) {
            setAdminData(response.data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
          setAdminData();
        }
      } else {
        setIsLoading(false);
      }
    }
    loadAdminFromCookies();

    return () => {
      setAdminData();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!AdminData,
        AdminData,
        LoginFun,
        Logout,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const Loader = ({ children }) => {
  const { isLoading } = useAuth();

  if (isLoading) {
    return <ScreenLoader />;
  }

  return children;
};

export const useAuth = () => useContext(AuthContext);
