import axios from "axios";
import api from "api";
import { setSnackbar } from "redux/actions/global";
import { getCookies } from "utils";
import { ErrorMessageFun } from "./index";

// upload api section
// =======================================================

export const UploadUpcomingBookFun = (
  formData,
  setLoading,
  setCreateUpcomingBook
) => {
  return async (dispatch) => {
    try {
      setLoading(true);

      const Token = getCookies();

      const response = await axios.post(api.uploadUpcomingBook, formData, {
        headers: {
          Authorization: Token,
        },
      });

      if (response.status === 200) {
        setLoading(false);
        dispatch(setSnackbar(true, "success", response.data.message));
        setCreateUpcomingBook({
          name: "",
          price: "",
          author: "",
          classification: "",
          language: "",
          isbn: "",
          description: "",
          image: "",
        });
      }
    } catch (error) {
      setLoading(false);
      ErrorMessageFun(error, dispatch, setSnackbar);
    }
  };
};

export const UploadBookFun = (formData, setLoading, setCreateBook) => {
  return async (dispatch) => {
    try {
      setLoading(true);

      const Token = getCookies();

      const response = await axios.post(api.uploadBook, formData, {
        headers: {
          Authorization: Token,
        },
      });

      if (response.status === 200) {
        setLoading(false);
        dispatch(setSnackbar(true, "success", response.data.message));
        setCreateBook({
          name: "",
          price: "",
          author: "",
          classification: "",
          language: "",
          publishedYear: "",
          isbn: "",
          description: "",
          images: "",
        });
      }
    } catch (error) {
      setLoading(false);
      ErrorMessageFun(error, dispatch, setSnackbar);
    }
  };
};

export const UploadEventFun = (formData, setLoading, setCreateEvent) => {
  return async (dispatch) => {
    try {
      setLoading(true);

      const Token = getCookies();

      const response = await axios.post(api.uploadEvent, formData, {
        headers: {
          Authorization: Token,
        },
      });

      if (response.status === 200) {
        setLoading(false);
        dispatch(setSnackbar(true, "success", response.data.message));
        setCreateEvent({
          title: "",
          time: "",
          description: "",
          images: "",
        });
      }
    } catch (error) {
      setLoading(false);
      ErrorMessageFun(error, dispatch, setSnackbar);
    }
  };
};

export const UploadPosterFun = (formData, setLoading, setCreatePoster) => {
  return async (dispatch) => {
    try {
      setLoading(true);

      const Token = getCookies();

      const response = await axios.post(api.uploadPoster, formData, {
        headers: {
          Authorization: Token,
        },
      });

      if (response.status === 200) {
        setLoading(false);
        dispatch(setSnackbar(true, "success", response.data.message));
        setCreatePoster({
          LaptopImage: "",
          MobileImage: "",
        });
      }
    } catch (error) {
      setLoading(false);
      ErrorMessageFun(error, dispatch, setSnackbar);
    }
  };
};

export const UploadPinCodeFun = (
  CreatePinCode,
  setLoading,
  setCreatePinCode
) => {
  return async (dispatch) => {
    try {
      setLoading(true);

      const Token = getCookies();

      const response = await axios.post(
        api.uploadPinCode,
        {
          pincode: CreatePinCode,
        },
        {
          headers: {
            Authorization: Token,
          },
        }
      );

      if (response.status === 200) {
        setLoading(false);
        dispatch(setSnackbar(true, "success", response.data.message));
        setCreatePinCode("");
      }
    } catch (error) {
      setLoading(false);
      ErrorMessageFun(error, dispatch, setSnackbar);
    }
  };
};

export const UploadTagFun = (CreateTag, setCreateTag, setLoading) => {
  return async (dispatch) => {
    try {
      const { type, value } = CreateTag;

      setLoading(true);

      const Token = getCookies();

      const response = await axios.post(
        api.uploadTag,
        {
          type,
          value,
        },
        {
          headers: {
            Authorization: Token,
          },
        }
      );

      if (response.status === 200) {
        setLoading(false);
        dispatch(setSnackbar(true, "success", response.data.message));
        setCreateTag({
          type: "",
          value: "",
        });
      }
    } catch (error) {
      setLoading(false);
      ErrorMessageFun(error, dispatch, setSnackbar);
    }
  };
};

// Delete  api sections
// =========================================================
export const DeleteBookFun = (id, keys, BookList, setBookList) => {
  return async (dispatch) => {
    try {
      const Token = getCookies();

      const response = await axios.post(
        `${api.deleteBook}/${id}`,
        {
          keys: keys,
        },
        {
          headers: {
            Authorization: Token,
          },
        }
      );

      if (response.status === 200) {
        dispatch(setSnackbar(true, "success", response.data.message));

        const newBookList = BookList.filter((list) => {
          return list._id !== id;
        });

        setBookList(newBookList);
      }
    } catch (error) {
      ErrorMessageFun(error, dispatch, setSnackbar);
    }
  };
};

export const DeleteUpcomingBookFun = (
  id,
  key,
  UpcomingBookList,
  setUpcomingBookList
) => {
  return async (dispatch) => {
    try {
      const Token = getCookies();

      const response = await axios.post(
        `${api.deleteUpcomingBook}/${id}`,
        {
          key: key,
        },
        {
          headers: {
            Authorization: Token,
          },
        }
      );

      if (response.status === 200) {
        dispatch(setSnackbar(true, "success", response.data.message));

        const newBookList = UpcomingBookList.filter((list) => {
          return list._id !== id;
        });

        setUpcomingBookList(newBookList);
      }
    } catch (error) {
      ErrorMessageFun(error, dispatch, setSnackbar);
    }
  };
};

export const DeleteEventFun = (id, keys, EventList, setEventList) => {
  return async (dispatch) => {
    try {
      const Token = getCookies();

      const response = await axios.post(
        `${api.deleteEvent}/${id}`,
        {
          keys: keys,
        },
        {
          headers: {
            Authorization: Token,
          },
        }
      );

      if (response.status === 200) {
        dispatch(setSnackbar(true, "success", response.data.message));

        const newEventList = EventList.filter((list) => {
          return list._id !== id;
        });

        setEventList(newEventList);
      }
    } catch (error) {
      ErrorMessageFun(error, dispatch, setSnackbar);
    }
  };
};

export const DeletePosterFun = (id, keys, PosterList, setPosterList) => {
  return async (dispatch) => {
    try {
      const Token = getCookies();

      const response = await axios.post(
        `${api.deletePoster}/${id}`,
        {
          keys: keys,
        },
        {
          headers: {
            Authorization: Token,
          },
        }
      );

      if (response.status === 200) {
        dispatch(setSnackbar(true, "success", response.data.message));

        const newEventList = PosterList.filter((list) => {
          return list._id !== id;
        });

        setPosterList(newEventList);
      }
    } catch (error) {
      ErrorMessageFun(error, dispatch, setSnackbar);
    }
  };
};

export const DeletePinCodeFun = (pinCode, PinCodeList, setPinCodeList) => {
  return async (dispatch) => {
    try {
      const Token = getCookies();

      const response = await axios.post(
        api.deletePinCode,
        {
          pincode: pinCode,
        },
        {
          headers: {
            Authorization: Token,
          },
        }
      );

      if (response.status === 200) {
        dispatch(setSnackbar(true, "success", response.data.message));

        const newPinCodeList = PinCodeList.filter((list) => {
          return list.pincode !== pinCode;
        });

        setPinCodeList(newPinCodeList);
      }
    } catch (error) {
      ErrorMessageFun(error, dispatch, setSnackbar);
    }
  };
};

export const DeleteTagFun = (id, TagList, setTag) => {
  return async (dispatch) => {
    try {
      const Token = getCookies();

      const response = await axios.post(
        api.deleteTag,
        {
          id,
        },
        {
          headers: {
            Authorization: Token,
          },
        }
      );

      if (response.status === 200) {
        dispatch(setSnackbar(true, "success", response.data.message));

        const newTagList = TagList.filter((list) => {
          return list._id !== id;
        });

        setTag({
          List: newTagList,
          status: null,
        });
      }
    } catch (error) {
      ErrorMessageFun(error, dispatch, setSnackbar);
    }
  };
};

// get api section
// =====================================================
export const getOrderFun = async (userId, orderId, setOrder) => {
  try {
    setOrder({
      process: true,
    });

    const Token = getCookies();

    const response = await axios.post(
      api.getOrder,
      {
        orderId: orderId,
        userId: userId,
      },
      {
        headers: {
          Authorization: Token,
        },
      }
    );

    if (response.status === 200) {
      setOrder({
        detail: response.data,
        error: null,
        process: false,
      });
    }
  } catch (error) {
    setOrder({
      detail: null,
      error: error.response.status,
      process: false,
    });
  }
};

export const getAllTagFun = async (selected, setLoading, setTag) => {
  try {
    const Token = getCookies();

    const response = await axios.post(
      api.getTag,
      {
        type: selected,
      },
      {
        headers: {
          Authorization: Token,
        },
      }
    );

    if (response.status === 200) {
      setLoading(false);
      setTag({
        List: response.data,
        status: null,
      });
    }
  } catch (error) {
    setLoading(false);
    if (error.response.status === 500) {
      setTag({
        List: [],
        status: error.response.status,
      });
    } else {
      setTag({
        List: [],
        status: null,
      });
    }
  }
};

export const getAuthorsFun = async (setAuthorList) => {
  try {
    const Token = getCookies();

    const response = await axios.post(
      api.getTag,
      {
        type: "author",
      },
      {
        headers: {
          Authorization: Token,
        },
      }
    );

    if (response.status === 200) {
      setAuthorList(response.data);
    }
  } catch (error) {
    setAuthorList([]);
  }
};

export const getClassificationFun = async (setClassificationList) => {
  try {
    const Token = getCookies();

    const response = await axios.post(
      api.getTag,
      {
        type: "classification",
      },
      {
        headers: {
          Authorization: Token,
        },
      }
    );

    if (response.status === 200) {
      setClassificationList(response.data);
    }
  } catch (error) {
    setClassificationList([]);
  }
};

// update api section
// ========================================================

export const UpdateStatusFun = (status, value, userId, orderId, setOrder) => {
  return async (dispatch) => {
    try {
      const Token = getCookies();

      const response = await axios.post(
        api.updateStatus,
        {
          status: status,
          value: value + 1,
          orderId: orderId,
          userId: userId,
        },
        {
          headers: {
            Authorization: Token,
          },
        }
      );

      if (response.status === 200) {
        dispatch(setSnackbar(true, "success", response.data.message));

        const res = await axios.post(
          api.getOrder,
          {
            orderId: orderId,
            userId: userId,
          },
          {
            headers: {
              Authorization: Token,
            },
          }
        );
        if (res.status === 200) {
          setOrder({
            detail: response.data,
            error: null,
            process: false,
          });
        }
      }
    } catch (error) {
      ErrorMessageFun(error, dispatch, setSnackbar);
    }
  };
};
