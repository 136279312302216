const Elements = {
  Email: {
    label: "Email",
    name: "email",
    type: "email",
    placeholder: "Email",
    required: true,
    controlId: "formBasicEmail",
  },
  Password: {
    label: "Password",
    name: "password",
    type: "password",
    placeholder: "Password",
    required: true,
    controlId: "formBasicPassword",
  },
  Name: {
    label: "Name",
    name: "name",
    type: "text",
    placeholder: "Name",
    required: true,
    controlId: "formBasicName",
  },
  Price: {
    label: "Price",
    name: "price",
    type: "number",
    placeholder: "Price",
    required: true,
    controlId: "formBasicPrice",
  },
  Author: {
    label: "Author",
    name: "author",
    type: "text",
    placeholder: "Author",
    required: true,
    controlId: "formBasicAuthor",
  },
  Classification: {
    label: "Classification",
    name: "classification",
    type: "text",
    placeholder: "Classification",
    required: true,
    controlId: "formBasicClassification",
  },
  Language: {
    label: "Language",
    name: "language",
    type: "text",
    placeholder: "Language",
    required: true,
    controlId: "formBasicLanguage",
  },
  Isbn: {
    label: "Isbn",
    name: "isbn",
    type: "number",
    placeholder: "Isbn",
    required: true,
    controlId: "formBasicIsbn",
  },
  Description: {
    type: "textarea",
    label: "Description",
    name: "description",
    placeholder: "Description",
    required: true,
    controlId: "formBasicDescription",
  },
  Image: {
    type: "file",
    name: "image",
    label: "Upload Image",
    placeholder: "Select Image",
    required: true,
    controlId: "formBasicImage",
  },
  Images: {
    type: "file",
    label: "Upload Images",
    name: "images",
    placeholder: "Select Images",
    required: true,
    controlId: "formBasicImages",
  },
  PublishedYear: {
    type: "number",
    label: "PublishedYear",
    name: "publishedYear",
    placeholder: "PublishedYear",
    required: true,
    controlId: "formBasicPublishedYear",
  },
  Title: {
    type: "text",
    label: "Title",
    name: "title",
    placeholder: "Title",
    required: true,
    controlId: "formBasicTitle",
  },
  Time: {
    type: "text",
    label: "Time",
    name: "time",
    placeholder: "Time",
    required: true,
    controlId: "formBasicTime",
  },
  Image1: {
    type: "file",
    label: "Upload Images",
    name: "OneImg",
    placeholder: "Select Images",
    required: true,
    controlId: "formBasicOneImg",
  },
  Image2: {
    type: "file",
    label: "Upload Images",
    name: "SecondImg",
    placeholder: "Select Images",
    required: true,
    controlId: "formBasicSecondImg",
  },
  PinCode: {
    type: "number",
    label: "PinCode",
    name: "PinCode",
    placeholder: "PinCode",
    required: true,
    controlId: "formBasicPinCode",
  },
  Type: {
    label: "Type",
    name: "type",
    type: "text",
    placeholder: "Select type",
    required: true,
    controlId: "formBasicType",
  },
  Value: {
    label: "value",
    name: "value",
    type: "text",
    placeholder: "Enter value",
    required: true,
    controlId: "formBasicValue",
  },
};

export const AdminLoginFields = [Elements.Email, Elements.Password];

export const UploadUpcomingBookFields = [
  Elements.Name,
  Elements.Price,
  Elements.Author,
  Elements.Classification,
  Elements.Language,
  Elements.Isbn,
  Elements.Description,
  Elements.Image,
];

export const UploadBookFields = [
  Elements.Name,
  Elements.Price,
  Elements.Author,
  Elements.Classification,
  Elements.Language,
  Elements.PublishedYear,
  Elements.Isbn,
  Elements.Description,
  Elements.Image1,
  Elements.Image2,
];

export const UploadEventFields = [
  Elements.Title,
  Elements.Time,
  Elements.Description,
  Elements.Images,
];

export const UploadPosterFields = [Elements.Image1, Elements.Image2];

export const UploadPinCodeFields = [Elements.PinCode];

export const UploadTagCodeFields = [Elements.Type, Elements.Value];
