import React, { useState } from "react";
import TitleToggle from "components/globalComponents/TitleToggle";
import Input from "Layout/Form/Input";
import FormWrapper from "styles/global/form.style";
import { UploadPinCodeFields } from "Layout/FormElement";
import Button from "Layout/Button";
import { UploadPinCodeFun } from "utils/postMethodFunction";
import { useDispatch } from "react-redux";

const UploadPinCode = () => {
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);

  const [CreatePinCode, setCreatePinCode] = useState("");

  const UploadHandler = (event) => {
    event.preventDefault();
    dispatch(UploadPinCodeFun(CreatePinCode, setLoading, setCreatePinCode));
  };

  return (
    <>
      <TitleToggle Title="PinCode" />
      <div className="RightPanel">
        <FormWrapper>
          <div className="container UploadPinCode">
            <div className="row">
              <div className="col-12 d-flex justify-content-center mt-4">
                <form method="POST" onSubmit={UploadHandler}>
                  <div className="form_section">
                    <div className="row d-flex justify-content-center">
                      <div className="col-lg-6 col-10">
                        <div className="row">
                          {UploadPinCodeFields.map((List, ind) => {
                            const {
                              name,
                              type,
                              controlId,
                              required,
                              placeholder,
                            } = List;
                            return (
                              <div className="col-12 form_field" key={ind}>
                                <Input
                                  type={type}
                                  name={name}
                                  placeholder={placeholder}
                                  id={controlId}
                                  onChange={(e) => {
                                    if (e.target.value.length <= 6) {
                                      setCreatePinCode(e.target.value);
                                    }
                                  }}
                                  required={required}
                                  value={CreatePinCode}
                                  autoComplete="off"
                                />
                              </div>
                            );
                          })}

                          <div className="col-12 Upload_button mt-4">
                            <Button
                              type="submit"
                              disabled={Loading}
                              active={1}
                              br="50px"
                              p="10px"
                              id="btn"
                            >
                              {Loading ? (
                                <p
                                  style={{
                                    color: "snow",
                                    fontSize: "1.7rem",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItem: "center",
                                    marginTop: "3px",
                                  }}
                                >
                                  <i className="zmdi zmdi-rotate-left zmdi-hc-spin-reverse"></i>
                                </p>
                              ) : (
                                "Upload"
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </FormWrapper>
      </div>
    </>
  );
};

export default UploadPinCode;
