import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineDashboard } from "react-icons/md";
import { RiUploadCloud2Line } from "react-icons/ri";
import { HiOutlineShoppingBag } from "react-icons/hi";

// AiOutlinePlus;
// AiOutlineMinus

export const LeftPanelNavList = [
  {
    id: 1,
    name: "Dashboard",
    icons: <MdOutlineDashboard className="list_icon" />,
    href: "/",
  },
  {
    id: 2,
    name: "Upload",
    icons: <RiUploadCloud2Line className="dropdown_list_icon" />,
    submenus: [
      {
        id: 3,
        name: "Book",
        icons: <RiUploadCloud2Line className="list_icon" />,
        href: "/upload/book",
      },
      {
        id: 4,
        name: "Upcoming Book",
        icons: <RiUploadCloud2Line className="list_icon" />,
        href: "/upload/upcomingBook",
      },
      {
        id: 5,
        name: "Event",
        icons: <RiUploadCloud2Line className="list_icon" />,
        href: "/upload/event",
      },
      {
        id: 6,
        name: "Poster",
        icons: <RiUploadCloud2Line className="list_icon" />,
        href: "/upload/poster",
      },
      {
        id: 7,
        name: "Pincode",
        icons: <RiUploadCloud2Line className="list_icon" />,
        href: "/upload/pincode",
      },
      {
        id: 8,
        name: "Tag",
        icons: <RiUploadCloud2Line className="list_icon" />,
        href: "/upload/tag",
      },
    ],
  },
  {
    id: 9,
    name: "Delete",
    icons: <AiOutlineDelete className="dropdown_list_icon" />,
    submenus: [
      {
        id: 10,
        name: "Books",
        icons: <AiOutlineDelete className="list_icon" />,
        href: "/delete/books",
      },
      {
        id: 11,
        name: "Upcoming Books",
        icons: <AiOutlineDelete className="list_icon" />,
        href: "/delete/upcomingBooks",
      },
      {
        id: 12,
        name: "Events",
        icons: <AiOutlineDelete className="list_icon" />,
        href: "/delete/events",
      },
      {
        id: 13,
        name: "Poster",
        icons: <AiOutlineDelete className="list_icon" />,
        href: "/delete/posters",
      },
      {
        id: 14,
        name: "PinCode",
        icons: <AiOutlineDelete className="list_icon" />,
        href: "/delete/pincode",
      },
      {
        id: 15,
        name: "Tag",
        icons: <AiOutlineDelete className="list_icon" />,
        href: "/delete/tag",
      },
    ],
  },
  {
    id: 15,
    name: "Orders",
    icons: <HiOutlineShoppingBag className="dropdown_list_icon" />,
    submenus: [
      {
        id: 16,
        name: "Current Orders",
        icons: <HiOutlineShoppingBag className="list_icon" />,
        href: "/orders/current-orders",
      },
      {
        id: 17,
        name: "Past Orders",
        icons: <HiOutlineShoppingBag className="list_icon" />,
        href: "/orders/past-orders",
      },
    ],
  },
];

export const DeleteBook_Table_Headers = [
  {
    id: 1,
    name: "Book",
  },
  {
    id: 2,
    name: "Name",
  },
  {
    id: 3,
    name: "Price",
  },
  {
    id: 4,
    name: "Author",
  },
  {
    id: 5,
    name: "Delete",
  },
];

export const DeleteUpcomingBook_Table_Headers = [
  {
    id: 1,
    name: "Book",
  },
  {
    id: 2,
    name: "Name",
  },
  {
    id: 3,
    name: "Price",
  },
  {
    id: 4,
    name: "Author",
  },
  {
    id: 5,
    name: "Delete",
  },
];

export const DeleteEventBook_Table_Headers = [
  {
    id: 1,
    name: "Event",
  },
  {
    id: 2,
    name: "Title",
  },
  {
    id: 3,
    name: "Time",
  },
  {
    id: 4,
    name: "Delete",
  },
];
