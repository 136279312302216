import React, { memo } from "react";
import Card from "Layout/Card";

const Items = ({ images, name, quantity, price }) => {
  return (
    <div className="row">
      <div className="col-lg-12 mt-2 mt-2">
        <Card className="order_card" p="1.5rem">
          <div className="row">
            <div className="col-lg-9 col-md-8 col-12 d-flex flex-row align-items-center">
              <div className="img_section">
                <img src={images?.front?.Location} alt={name} loading="lazy" />
              </div>
              <div className="book_name pl-3">
                <span>{name}</span>
                <div className="price mt-1">
                  <span>
                    ₹{price}({quantity})
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-12 d-flex align-items-center justify-content-end">
              <div className="price">
                <span>
                  ₹{price}({quantity})
                </span>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default memo(Items);
