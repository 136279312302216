import { combineReducers } from "redux";
import SnackbarReducer from "./SnackbarReducer";
import LeftPanelReducer from "./LeftPanelReducer";

const rootReducer = combineReducers({
  SnackbarReducer,
  LeftPanelReducer,
});

export default rootReducer;
