import React from "react";
import { NavLink } from "react-router-dom";
import { LeftPanelNavList } from "assets/api";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineClose } from "react-icons/md";
import { CloseLeftPanel } from "redux/actions/global";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";

const LeftPanel = () => {
  const dispatch = useDispatch();
  const LeftPanelState = useSelector((state) => state.LeftPanelReducer);

  const Toggle_Button = (id1, id2) => {
    const Icon1 = document.getElementById(id1);
    const Icon2 = document.getElementById(id2);

    const Icon1ClassPro = Icon1.className.baseVal.split(" ");

    if (Icon1ClassPro[1] === "show") {
      Icon1.classList.add("hide");
      Icon1.classList.remove("show");

      Icon2.classList.add("show");
      Icon2.classList.remove("hide");
    } else if (Icon1ClassPro[1] === "hide") {
      Icon1.classList.add("show");
      Icon1.classList.remove("hide");

      Icon2.classList.add("hide");
      Icon2.classList.remove("show");
    }
  };

  return (
    <>
      <div className="LeftPanel" style={{ width: LeftPanelState.width }}>
        <div className="container-fluid LeftPanel_Main_top">
          <div className="row d-flex justify-content-center img_section">
            <div className="col-9 pt-1 d-flex align-items-center">
              <div className="logo_img ml-3">
                <img src="/images/Logo.png" alt="Loading..." />
              </div>
            </div>
            <div className="col-3 d-flex align-items-center">
              <div
                className="close_div mt-2"
                onClick={() => dispatch(CloseLeftPanel())}
              >
                <MdOutlineClose className="close_icon" />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid LeftPanel_Main_bottom p-0 m-0">
          <div className="row d-flex justify-content-center navbar_List">
            <div className="col-12">
              <ul className="Nav_List">
                {LeftPanelNavList.map((list, index) => {
                  return (
                    <div key={index}>
                      {list.submenus ? (
                        <div key={list.id}>
                          <li>
                            <a
                              className="dropdown_list d-flex align-items-center"
                              data-toggle="collapse"
                              href={`#${list.name}`}
                              role="button"
                              aria-expanded="false"
                              aria-controls={list.name}
                              onClick={() =>
                                Toggle_Button(
                                  `collapse_icon${list.id}`,
                                  `collapse_icon${list.id + 1}`
                                )
                              }
                            >
                              <div className="d-flex align-items-center">
                                {list.icons}
                                <span>{list.name}</span>
                              </div>
                              <div className="collapse_btn d-flex justify-content-end">
                                <MdKeyboardArrowRight
                                  className="collapse_icon show"
                                  id={`collapse_icon${list.id}`}
                                />
                                <MdKeyboardArrowDown
                                  className="collapse_icon hide"
                                  id={`collapse_icon${list.id + 1}`}
                                />
                              </div>
                            </a>
                          </li>

                          {list.submenus.map((submenu, ind) => (
                            <li key={list.id + ind + 1}>
                              <div className="collapse" id={list.name}>
                                <ul className="collapse_nav_list">
                                  <li>
                                    <NavLink
                                      to={submenu.href}
                                      className="noActive d-flex align-items-center"
                                      activeclassname="active"
                                    >
                                      {submenu.icons}
                                      <span>{submenu.name}</span>
                                    </NavLink>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          ))}
                        </div>
                      ) : (
                        <li key={list.id}>
                          <NavLink
                            to={list.href}
                            className="noActive d-flex align-items-center"
                            activeclassname="active"
                          >
                            {list.icons}
                            <span>{list.name}</span>
                          </NavLink>
                        </li>
                      )}
                    </div>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftPanel;
