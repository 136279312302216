import styled from "styled-components";

const TableWrapper = styled.div`
  .Delete {
    .table_section {
      .table_card {
        background: none;
        border-radius: 10px;
        max-height: 440px;
        overflow-y: scroll;
        overflow-x: scroll;

        &::-webkit-scrollbar {
          width: 4px;
          border-radius: 10px;
          background: ${(props) => props.theme.BodyScrollbarBg};
        }

        &::-webkit-scrollbar-track {
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: ${(props) => props.theme.BodyScrollbarColor};
          backdrop-filter: blur(5px);
          border-radius: 10px;
        }

        table {
          width: 100%;

          .table-hover {
            background: red;
          }

          th {
            position: sticky !important;
            vertical-align: middle !important;
            border-top: none !important;
            top: 0px;
            z-index: 800;
            background: ${(props) => props.theme.borderMain};
          }

          thead {
            border-radius: 10px 10px 0 0;

            tr {
              th {
                color: ${(props) => props.theme.BodyPrimary};
                border: none !important;
                font-size: 0.95rem;
                padding: 1.5rem 0.9rem;
              }
            }
          }

          tbody {
            border-left: 2px solid ${(props) => props.theme.BodyNeutralAlt};
            border-right: 2px solid ${(props) => props.theme.BodyNeutralAlt};
            border-bottom: 2px solid ${(props) => props.theme.BodyNeutralAlt};
            border-radius: 0px 0px 10px 10px;

            tr {
              td {
                color: ${(props) => props.theme.BodySecondary};
                font-weight: 500;
                font-size: 0.9rem;

                span {
                  overflow: hidden;
                  width: 100%;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                }

                img {
                  height: 80px;
                  width: auto;
                }
              }

              &:hover {
                background: rgba(0, 0, 0, 0.03);
              }
            }
          }
        }
      }
    }
  }
`;

export default TableWrapper;
