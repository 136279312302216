import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TitleToggle from "components/globalComponents/TitleToggle";
import OrdersWrapper from "styles/Order.style";
import { getPastOrdersFun } from "utils/getMethodFunction";
import DataLoader from "components/globalComponents/Loaders/DataLoader";
import Error from "components/globalComponents/Error";
import Empty from "components/globalComponents/Empty";
import Card from "Layout/Card";
import Button from "Layout/Button";
import moment from "moment";

const PastOrder = () => {
  const navigate = useNavigate();
  const [PastOrders, setPastOrders] = useState({
    list: [],
    error: null,
    process: false,
  });

  useEffect(() => {
    const callAsync = async () => {
      await getPastOrdersFun(setPastOrders);
    };

    callAsync();

    return () => {
      setPastOrders({
        list: [],
        error: null,
        process: false,
      });
    };
  }, []);

  if (PastOrders?.process) {
    return <DataLoader />;
  } else if (PastOrders?.error) {
    return <Error status={PastOrders?.error} />;
  }

  return (
    <>
      <TitleToggle Title="Past Orders" />
      <div className="RightPanel">
        <OrdersWrapper>
          <div className="container Orders">
            {PastOrders?.list?.length > 0 ? (
              <div className="row d-flex justify-content-center">
                {PastOrders?.list.map((list) => {
                  return (
                    <div
                      className="col-lg-9 col-md-10 col-12 mt-4"
                      key={list.user_id}
                    >
                      <Card className="order_card" p="1.5rem">
                        <div className="row d-flex align-items-center">
                          <div className="col-12 d-flex align-items-center">
                            <div className="time">
                              {moment(list?.date).format("MMM Do YY, h:mm a")}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 order_list">
                            {list?.orders?.orderDetails.map((entry) => {
                              return (
                                <div
                                  className="row d-flex align-items-lg-center align-items-md-center align-items-start mt-4"
                                  key={entry._id}
                                >
                                  <div className="col-lg-8 col-md-6 col-12 d-flex flex-row">
                                    <div className="img_section">
                                      <img
                                        src={entry.images.front.Location}
                                        alt={entry.name}
                                        loading="lazy"
                                      />
                                    </div>
                                    <div className="book_name d-flex align-items-lg-center flex-lg-row flex-md-row flex-column pl-3">
                                      <span>{entry.name}</span>
                                      <div className="price my-lg-0 my-md-0 my-1">
                                        <span>
                                          ₹{entry.price}({entry.quantity})
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 col-md-4 col-12">
                                    <div className="price">
                                      <span>
                                        ₹{entry.price}({entry.quantity})
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2 col-12 d-flex justify-content-end">
                                    <div className="details_btn">
                                      <Button
                                        active={4}
                                        p="0.2rem 0.5rem"
                                        br="4px"
                                        size="1rem"
                                        id="btn"
                                        onClick={() =>
                                          navigate(
                                            `/orders/details?userId=${list.user_id}&orderId=${list?.orders?._id}`
                                          )
                                        }
                                      >
                                        Details
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </Card>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="row">
                <div className="col-12 d-flex justify-content-center">
                  <Empty title="Orders is empty!" />
                </div>
              </div>
            )}
          </div>
        </OrdersWrapper>
      </div>
    </>
  );
};

export default PastOrder;
