import styled from "styled-components";

const FormWrapper = styled.div`
  .UploadUpcomingBook,
  .UploadBook,
  .UploadEvent,
  .UploadPoster,
  .UploadPinCode,
  .UploadTag {
    padding: 0px 0px 100px 0px;

    form {
      width: 100%;

      .form_field {
        margin-top: 10px;

        .upload {
          border: 1px solid ${(props) => props.theme.borderMain};
          outline: none;
          width: 100% !important;
          background: transparent;
          padding: 0.5rem 1.2rem;
          border-radius: 8px;
          color: ${(props) => props.theme.BodySecondary};
        }
      }
    }
  }
`;

export default FormWrapper;
