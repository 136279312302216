const BaseUrl = 'https://api.bhavyapublication.com/api';
// const BaseUrl = "http://localhost:4000/api";

const api = {
  login: BaseUrl + '/admin/login',
  profile: BaseUrl + '/admin/profile',

  getAllBooks: BaseUrl + '/book/getAll',
  getAllUpcomingBook: BaseUrl + '/upcomingBook/getAll',
  getAllEvent: BaseUrl + '/eventDetail/getAll',
  getAllPoster: BaseUrl + '/poster/getAll',
  getAllPinCode: BaseUrl + '/admin/pincode/getAll',
  getCurrentOrders: BaseUrl + '/admin/order/all',
  getPastOrders: BaseUrl + '/admin/order/pastOrders',
  getOrder: BaseUrl + '/admin/order/get',
  getTag: BaseUrl + '/admin/tag/get',

  uploadUpcomingBook: BaseUrl + '/upcomingBook/upload',
  uploadBook: BaseUrl + '/book/upload',
  uploadEvent: BaseUrl + '/eventDetail/upload',
  uploadPoster: BaseUrl + '/poster/upload',
  uploadPinCode: BaseUrl + '/admin/pincode/add',
  uploadTag: BaseUrl + '/admin/tag/add',

  deleteBook: BaseUrl + '/book/delete',
  deleteUpcomingBook: BaseUrl + '/upcomingBook/delete',
  deleteEvent: BaseUrl + '/eventDetail/delete',
  deletePoster: BaseUrl + '/poster/delete',
  deletePinCode: BaseUrl + '/admin/pincode/delete',
  deleteTag: BaseUrl + '/admin/tag/remove',

  updateStatus: BaseUrl + '/admin/order/updateStatus',
};

export default api;
