import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getOrderFun, UpdateStatusFun } from "utils/postMethodFunction";
import OrderWrapper from "./Order.style";
import TitleToggle from "components/globalComponents/TitleToggle";
import DataLoader from "components/globalComponents/Loaders/DataLoader";
import Error from "components/globalComponents/Error";
import Items from "./Items";
import Address from "./Address";
import Status from "./Status";
import Empty from "components/globalComponents/Empty";

const Order = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [order, setOrder] = useState({
    detail: null,
    error: null,
    process: false,
  });

  const UpdateStatus = async (status, value) => {
    const userId = searchParams.get("userId");
    const orderId = searchParams.get("orderId");
    if (value <= 0) {
      dispatch(UpdateStatusFun(status, value, userId, orderId, setOrder));
    }
  };

  useEffect(() => {
    if (searchParams) {
      const userId = searchParams.get("userId");
      const orderId = searchParams.get("orderId");

      const callAsync = async () => {
        await getOrderFun(userId, orderId, setOrder);
      };

      callAsync();
    }
    return () => {
      setOrder({
        detail: null,
        error: null,
        process: false,
      });
    };
  }, [searchParams]);

  if (order?.process) {
    return <DataLoader />;
  } else if (order?.error) {
    return <Error status={order?.error} />;
  }

  return (
    <>
      <TitleToggle Title="Details" />
      <div className="RightPanel">
        <OrderWrapper>
          <div className="container">
            <div className="OrderDetails">
              {order?.detail ? (
                <div className="row">
                  <div className="col-12">
                    <div className="OrderAddress_card">
                      <div className="row">
                        <Address {...order?.detail} />
                        <Status
                          {...order?.detail?.statusDetails}
                          date={order?.detail?.date}
                          UpdateStatus={UpdateStatus}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mt-4">
                    <div className="order_list">
                      <div className="row">
                        <div className="col-lg-6 col-12">
                          {order?.detail?.orderDetails?.map((entry) => {
                            return <Items key={entry._id} {...entry} />;
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    <Empty title="Order is empty!" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </OrderWrapper>
      </div>
    </>
  );
};

export default Order;
