import React from "react";
import LoginAdmin from "./LoginAdmin";
import HeaderAdminLogin from "./HeaderAdminLogin";
import HomeWrapper from "./Home.style";

const Home = () => {
  return (
    <HomeWrapper>
      <HeaderAdminLogin />
      <LoginAdmin />
    </HomeWrapper>
  );
};

export default Home;
