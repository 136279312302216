import React from "react";
import styled from "styled-components";

const EmptyWrapper = styled.div`
  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      max-width: 90%;
    }

    span {
      color: ${(props) => props.theme.BodySecondary};
      font-size: 1.2rem;
    }
  }
`;

const Empty = ({ title }) => {
  return (
    <EmptyWrapper>
      <div className="empty">
        <img src="/images/Empty.png" alt="Empty" loading="lazy" />
        <span className="mt-3">{title}</span>
      </div>
    </EmptyWrapper>
  );
};

export default Empty;
