import { createGlobalStyle } from "styled-components";

export const lightTheme = {
  BodyPrimary: "#000",
  BodySecondary: "#707070",
  BodyLine: "#f0f0f0",
  BodyNeutralAlt: "#f5f5f5",
  BodyNeutral: "#fff",
  BodyOverlay: "#000",
  BodyBrandAlt: "#0da098",
  BodyBrand: "#ff5c28",
  colorScheme: "light",
  borderMain: "#e6e6e6",
  BodyScrollbarBg: "inherit",
  BodyScrollbarColor: "rgba(0, 0, 0, 0.1)",
  BodyPC_Main: "rgb(34, 139, 34)",
};

export const darkTheme = {
  BodyPrimary: "#fff",
  BodySecondary: "#64676d",
  BodyLine: "#22252e",
  BodyNeutralAlt: "#1b1d23",
  BodyNeutral: "#0e1118",
  BodyOverlay: "#0e1118",
  BodyBrandAlt: "#0da098",
  BodyBrand: "#ff5c28",
  colorScheme: "dark",
  borderMain: "#e6e6e6",
  BodyScrollbarBg: "inherit",
  BodyScrollbarColor: "rgba(0, 0, 0, 0.3)",
  BodyPC_Main: "rgb(34, 139, 34)",
};

export const GlobalStyle = createGlobalStyle`
*,
*:before,
*:after {
    box-sizing: inherit;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body,
html {
    margin: 0;
    height: 100%;
    width: 100%;
}

html {
  margin: 0;
  padding: 0;
  text-size-adjust: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
}

::-webkit-scrollbar {
  height:8px;
  width: 8px;
  border-radius: 10px;
  background: ${(props) => props.theme.BodyScrollbarBg};;
}

::-webkit-scrollbar-corner {
  background: ${(props) => props.theme.BodyScrollbarColor};
}

::-webkit-scrollbar-thumb {
  background:   ${(props) => props.theme.BodyScrollbarColor};;
  border-radius: 10px;
  -webkit-border-radius: 1ex;
  backdrop-filter: blur(5px);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  font-family: IBM Plex Sans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  font-feature-settings: "tnum","tnum";
  background: #000;
  background:${(props) => props.theme.BodyNeutral};
  font-variant: tabular-nums;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {   
  font-size: 1.32rem;
  color: ${(props) => props.theme.BodyPrimary};
}

p {
  margin-bottom: 0 !important;
  color: inherit;
}


.container {
  max-width: 1352px !important;
}


.LeftPanel {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 230px;
  z-index: 1;
  transition: all 0.3s ease;
  background: transparent;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.RightPanel {
  position: relative;
  background:transparent;
  left: 230px;
  width: calc(100% - 230px);
  transition: all 0.5s ease;
}


@media only screen and (max-width: 1000px) {
  .LeftPanel {
    position: fixed;
    top: 0;
    left: 0;
    height: auto;
    width: 0;
    z-index: 999;
    overflow-x: hidden;
    transition: all 0.3s ease;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    background: white;
    border-radius:0px 16px 16px 0px;
  }

  .RightPanel {
    left: 0;
    width: 100%;
  }
}


@media only screen and (max-width: 900px) { 
  .container {
    padding-left:15px;
    padding-right:15px;
  }
}
`;
