import React, { useState } from "react";
import TitleToggle from "components/globalComponents/TitleToggle";
import Input from "Layout/Form/Input";
import FormWrapper from "styles/global/form.style";
import { UploadTagCodeFields } from "Layout/FormElement";
import { TagTypeOptions } from "Layout/FormElement/Options";
import Button from "Layout/Button";
import Select from "Layout/Form/Select";
import { UploadTagFun } from "utils/postMethodFunction";
import { useDispatch } from "react-redux";

const UploadTag = () => {
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);
  const [CreateTag, setCreateTag] = useState({
    type: "",
    value: "",
  });

  const FormDataHandler = (e) => {
    setCreateTag({
      ...CreateTag,
      [e.target.name]: e.target.value,
    });
  };

  const UploadHandler = (event) => {
    event.preventDefault();
    dispatch(UploadTagFun(CreateTag, setCreateTag, setLoading));
  };

  return (
    <>
      <TitleToggle Title="Upload Tag" />
      <div className="RightPanel">
        <FormWrapper>
          <div className="container UploadTag">
            <div className="row">
              <div className="col-12 d-flex justify-content-center mt-5">
                <form method="post" onSubmit={UploadHandler}>
                  <div className="form_section">
                    <div className="row d-flex justify-content-center">
                      <div className="col-lg-6 col-10">
                        <div className="row">
                          {UploadTagCodeFields.map((List, ind) => {
                            const {
                              name,
                              type,
                              controlId,
                              required,
                              placeholder,
                            } = List;
                            return (
                              <div className="col-12 form_field" key={ind}>
                                {name === "type" ? (
                                  <Select
                                    name={name}
                                    required={required}
                                    value={CreateTag[name]}
                                    onChange={FormDataHandler}
                                    p="0.5rem"
                                    br="8px"
                                  >
                                    <option
                                      value=""
                                      defaultValue="selected"
                                      disabled
                                      hidden
                                    >
                                      Select tag
                                    </option>
                                    {TagTypeOptions.map((item, index) => {
                                      return (
                                        <option value={item} key={index}>
                                          {item}
                                        </option>
                                      );
                                    })}
                                  </Select>
                                ) : (
                                  <Input
                                    type={type}
                                    name={name}
                                    placeholder={placeholder}
                                    id={controlId}
                                    onChange={FormDataHandler}
                                    required={required}
                                    value={CreateTag[name]}
                                    autoComplete="off"
                                  />
                                )}
                              </div>
                            );
                          })}

                          <div className="col-12 Upload_button mt-4">
                            <Button
                              type="submit"
                              disabled={Loading}
                              active={1}
                              br="50px"
                              p="10px"
                              id="btn"
                            >
                              {Loading ? (
                                <p
                                  style={{
                                    color: "snow",
                                    fontSize: "1.7rem",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItem: "center",
                                    marginTop: "3px",
                                  }}
                                >
                                  <i className="zmdi zmdi-rotate-left zmdi-hc-spin-reverse"></i>
                                </p>
                              ) : (
                                "Upload"
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </FormWrapper>
      </div>
    </>
  );
};

export default UploadTag;
