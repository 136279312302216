import React, { useState } from "react";
import TitleToggle from "components/globalComponents/TitleToggle";
import Input from "Layout/Form/Input";
import Textarea from "Layout/Form/Textarea";
import FormWrapper from "styles/global/form.style";
import { UploadEventFields } from "Layout/FormElement";
import Button from "Layout/Button";
import { UploadEventFun } from "utils/postMethodFunction";
import { useDispatch } from "react-redux";
import { makeFileList } from "utils";
import { setSnackbar } from "redux/actions/global";

// var MAX_WIDTH = 720;
// var MAX_HEIGHT = 480;
var MIME_TYPE = "image/png";
var QUALITY = 0.7;

const UploadEvent = () => {
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);

  const [CreateEvent, setCreateEvent] = useState({
    title: "",
    time: "",
    description: "",
    images: "",
  });

  const EventFormData = (e) => {
    setCreateEvent({
      ...CreateEvent,
      [e.target.name]: e.target.value,
    });
  };

  const EventFormImg = (e) => {
    setCreateEvent({ ...CreateEvent, images: e.target.files });
  };

  const UploadEvent = (event) => {
    event.preventDefault();

    const { title, time, description, images } = CreateEvent;

    if (images.length > 2) {
      dispatch(setSnackbar(true, "info", "Choose only two images"));
      return;
    }

    const blobURL = URL.createObjectURL(images[0]);
    const img = new Image();
    img.src = blobURL;
    img.onerror = function () {
      URL.revokeObjectURL(img.src);
      console.log("Cannot load image");
    };

    img.onload = function () {
      URL.revokeObjectURL(img.src);

      // const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
      const [newWidth, newHeight] = [img.width, img.height];

      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      canvas.toBlob(
        async (blob) => {
          const blobURL1 = URL.createObjectURL(images[1]);
          const img1 = new Image();
          img1.src = blobURL1;
          img1.onerror = function () {
            URL.revokeObjectURL(img1.src);
            console.log("Cannot load image");
          };

          img1.onload = function () {
            URL.revokeObjectURL(img1.src);

            // const [newWidth1, newHeight1] = calculateSize(
            //   img1,
            //   MAX_WIDTH,
            //   MAX_HEIGHT
            // );
            const [newWidth1, newHeight1] = [img1.width, img1.height];
            const canvas1 = document.createElement("canvas");
            canvas1.width = newWidth1;
            canvas1.height = newHeight1;
            const ctx1 = canvas1.getContext("2d");
            ctx1.drawImage(img1, 0, 0, newWidth1, newHeight1);

            canvas1.toBlob(
              async (blob1) => {
                var file1 = new File([blob], images[0].name, {
                  type: "png",
                });

                var file2 = new File([blob1], images[1].name, {
                  type: "png",
                });

                var newArr = makeFileList([file1, file2]);

                var formData = new FormData();

                for (var i = 0; i < newArr.length; i++) {
                  formData.append("images", newArr[i]);
                }

                formData.append("title", title);
                formData.append("time", time);
                formData.append("description", description);

                dispatch(UploadEventFun(formData, setLoading, setCreateEvent));
              },
              MIME_TYPE,
              QUALITY
            );
          };
        },
        MIME_TYPE,
        QUALITY
      );
    };
  };

  return (
    <>
      <TitleToggle Title="Event" />
      <div className="RightPanel">
        <FormWrapper>
          <div className="container UploadEvent">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <form method="POST" onSubmit={UploadEvent}>
                  <div className="form_section">
                    <div className="row d-flex justify-content-center">
                      <div className="col-lg-6 col-10">
                        <div className="row">
                          {UploadEventFields.map((List, ind) => {
                            const {
                              name,
                              type,
                              controlId,
                              required,
                              placeholder,
                            } = List;
                            return (
                              <div className="col-12 form_field" key={ind}>
                                {type === "text" && (
                                  <Input
                                    type={type}
                                    name={name}
                                    placeholder={placeholder}
                                    id={controlId}
                                    onChange={EventFormData}
                                    required={required}
                                    value={CreateEvent[name]}
                                    autoComplete="off"
                                  />
                                )}
                                {type === "textarea" && (
                                  <Textarea
                                    name={name}
                                    placeholder={placeholder}
                                    id={controlId}
                                    value={CreateEvent[name]}
                                    onChange={EventFormData}
                                    required={required}
                                    rows="4"
                                    cols="50"
                                  />
                                )}
                                {type === "file" && (
                                  <>
                                    <Input
                                      type={type}
                                      placeholder={placeholder}
                                      id={controlId}
                                      required={required}
                                      onChange={EventFormImg}
                                      autoComplete="off"
                                      accept="image/*"
                                      multiple
                                    />
                                    <p
                                      className="mt-2 ml-4"
                                      style={{ fontSize: "0.8rem" }}
                                    >
                                      Dimension should be 720x480
                                    </p>
                                  </>
                                )}
                              </div>
                            );
                          })}

                          <div className="col-12 Upload_button mt-4">
                            <Button
                              type="submit"
                              disabled={Loading}
                              active={1}
                              br="50px"
                              p="10px"
                              id="btn"
                            >
                              {Loading ? (
                                <p
                                  style={{
                                    color: "snow",
                                    fontSize: "1.7rem",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItem: "center",
                                    marginTop: "3px",
                                  }}
                                >
                                  <i className="zmdi zmdi-rotate-left zmdi-hc-spin-reverse"></i>
                                </p>
                              ) : (
                                "Upload"
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </FormWrapper>
      </div>
    </>
  );
};

export default UploadEvent;
