import React, { useState } from "react";
import { BiShowAlt } from "react-icons/bi";
import { BiHide } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { useAuth } from "middleware/AuthProvider";
import Button from "Layout/Button";
import { AdminLoginFields } from "Layout/FormElement";

const LoginAdmin = () => {
  const dispatch = useDispatch();
  const { LoginFun } = useAuth();
  const [showHide, setShowHide] = useState(false);
  const [Loading, setLoading] = useState(false);

  const [Login, setLogin] = useState({
    email: "",
    password: "",
  });

  const LoginFormData = (e) => {
    setLogin({ ...Login, [e.target.name]: e.target.value });
  };

  const LoginFunction = async (e) => {
    e.preventDefault();
    dispatch(LoginFun(Login, setLoading));
  };

  return (
    <>
      <section className="LoginAdmin my-5">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex justify-content-center from_tittle">
              <h5>Sign in</h5>
            </div>
          </div>
          <div className="row LoginAdmin_form">
            <div className="col-12">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-4 col-md-8 col-12 d-flex justify-content-center">
                  <form method="POST" onSubmit={LoginFunction}>
                    {AdminLoginFields.map((List, ind) => {
                      const { name, type, controlId, required, placeholder } =
                        List;
                      return (
                        <div className="col-12 input_form" key={ind}>
                          <input
                            type={ind !== 1 ? type : showHide ? "text" : type}
                            name={name}
                            placeholder={placeholder}
                            autoComplete="off"
                            id={controlId}
                            value={Login[name]}
                            onChange={LoginFormData}
                            required={required}
                          />

                          {ind === 1 && (
                            <>
                              {showHide ? (
                                <BiShowAlt
                                  className="icon1"
                                  onClick={() => setShowHide(false)}
                                />
                              ) : (
                                <BiHide
                                  className="icon1"
                                  onClick={() => setShowHide(true)}
                                />
                              )}
                            </>
                          )}
                        </div>
                      );
                    })}

                    <div className="col-12 input_button">
                      <div className="row d-flex justify-content-center text-center">
                        <div className="col-lg-10 col-md-10 col-11">
                          <Button
                            type="submit"
                            disabled={Loading}
                            active={1}
                            br="50px"
                            p="10px"
                            id="btn"
                          >
                            {Loading ? (
                              <p
                                style={{
                                  color: "snow",
                                  fontSize: "1.7rem",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItem: "center",
                                  marginTop: "3px",
                                }}
                              >
                                <i className="zmdi zmdi-rotate-left zmdi-hc-spin-reverse"></i>
                              </p>
                            ) : (
                              "SIGN IN"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginAdmin;
