import React, { useState, useEffect } from "react";
import TitleToggle from "components/globalComponents/TitleToggle";
import Input from "Layout/Form/Input";
import Textarea from "Layout/Form/Textarea";
import Select from "Layout/Form/Select";
import FormWrapper from "styles/global/form.style";
import { UploadUpcomingBookFields } from "Layout/FormElement";
import Button from "Layout/Button";
import {
  UploadUpcomingBookFun,
  getAuthorsFun,
  getClassificationFun,
} from "utils/postMethodFunction";
import { useDispatch } from "react-redux";

// var MAX_WIDTH = 112;
// var MAX_HEIGHT = 160;
var MIME_TYPE = "image/png";
var QUALITY = 0.7;

const UploadUpcomingBook = () => {
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);
  const [authorList, setAuthorList] = useState([]);
  const [classificationList, setClassificationList] = useState([]);

  const [CreateUpcomingBook, setCreateUpcomingBook] = useState({
    name: "",
    price: "",
    author: "",
    classification: "",
    language: "",
    isbn: "",
    description: "",
    image: "",
  });

  const UpcomingFormData = (e) => {
    setCreateUpcomingBook({
      ...CreateUpcomingBook,
      [e.target.name]: e.target.value,
    });
  };

  const UpcomingFormImg = (e) => {
    setCreateUpcomingBook({ ...CreateUpcomingBook, image: e.target.files[0] });
  };

  const UploadUpcomingBook = async (event) => {
    event.preventDefault();

    const {
      name,
      price,
      author,
      classification,
      language,
      isbn,
      description,
      image,
    } = CreateUpcomingBook;

    const blobURL = URL.createObjectURL(image);
    const img = new Image();
    img.src = blobURL;
    img.onerror = function () {
      URL.revokeObjectURL(img.src);
      console.log("Cannot load image");
    };

    img.onload = function () {
      URL.revokeObjectURL(img.src);

      // const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
      const [newWidth, newHeight] = [img.width, img.height];

      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      canvas.toBlob(
        async (blob) => {
          var file = new File([blob], image.name, {
            type: "image/png",
          });

          var formData = new FormData();

          formData.append("name", name);
          formData.append("price", price);
          formData.append("author", author);
          formData.append("classification", classification);
          formData.append("language", language);
          formData.append("isbn", isbn);
          formData.append("description", description);
          formData.append("image", file);

          dispatch(
            UploadUpcomingBookFun(formData, setLoading, setCreateUpcomingBook)
          );
        },
        MIME_TYPE,
        QUALITY
      );
    };
  };

  useEffect(() => {
    const callAsync = async () => {
      await getAuthorsFun(setAuthorList);
      await getClassificationFun(setClassificationList);
    };
    callAsync();

    return () => {
      setAuthorList([]);
      setClassificationList([]);
    };
  }, []);

  return (
    <>
      <TitleToggle Title="Upcoming Book" />
      <div className="RightPanel">
        <FormWrapper>
          <div className="container UploadUpcomingBook">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <form method="POST" onSubmit={UploadUpcomingBook}>
                  <div className="form_section">
                    <div className="row d-flex justify-content-center">
                      <div className="col-lg-6 col-10">
                        <div className="row">
                          {UploadUpcomingBookFields.map((List, ind) => {
                            const {
                              name,
                              type,
                              controlId,
                              required,
                              placeholder,
                            } = List;
                            return (
                              <div className="col-12 form_field" key={ind}>
                                {name === "name" ||
                                name === "price" ||
                                name === "language" ||
                                name === "isbn" ? (
                                  <Input
                                    type={type}
                                    name={name}
                                    placeholder={placeholder}
                                    id={controlId}
                                    onChange={UpcomingFormData}
                                    required={required}
                                    value={CreateUpcomingBook[name]}
                                    autoComplete="off"
                                  />
                                ) : null}

                                {name === "author" ||
                                name === "classification" ? (
                                  <Select
                                    name={name}
                                    required={required}
                                    value={CreateUpcomingBook[name]}
                                    onChange={UpcomingFormData}
                                    p="0.5rem"
                                    br="8px"
                                  >
                                    {name === "author" && (
                                      <>
                                        <option
                                          value=""
                                          defaultValue="selected"
                                          disabled
                                          hidden
                                        >
                                          Select author
                                        </option>
                                        {authorList?.map((item) => {
                                          return (
                                            <option
                                              value={item?.value}
                                              key={item?._id}
                                            >
                                              {item?.value}
                                            </option>
                                          );
                                        })}
                                      </>
                                    )}
                                    {name === "classification" && (
                                      <>
                                        <option
                                          value=""
                                          defaultValue="selected"
                                          disabled
                                          hidden
                                        >
                                          Select classification
                                        </option>
                                        {classificationList?.map((item) => {
                                          return (
                                            <option
                                              value={item?.value}
                                              key={item?._id}
                                            >
                                              {item?.value}
                                            </option>
                                          );
                                        })}
                                      </>
                                    )}
                                  </Select>
                                ) : null}

                                {name === "description" && (
                                  <Textarea
                                    name={name}
                                    placeholder={placeholder}
                                    id={controlId}
                                    value={CreateUpcomingBook[name]}
                                    onChange={UpcomingFormData}
                                    required={required}
                                    rows="4"
                                    cols="50"
                                  />
                                )}
                                {name === "image" && (
                                  <>
                                    <label className="upload">
                                      <Input
                                        type={type}
                                        placeholder={placeholder}
                                        id={controlId}
                                        required={required}
                                        onChange={UpcomingFormImg}
                                        autoComplete="off"
                                        accept="image/*"
                                        style={{ display: "none" }}
                                      />
                                      {CreateUpcomingBook?.image?.name
                                        ? CreateUpcomingBook?.image?.name
                                        : " Upload font file"}
                                    </label>
                                    <p
                                      className="mt-2 ml-4"
                                      style={{ fontSize: "0.8rem" }}
                                    >
                                      Dimension should be 300x427
                                    </p>
                                  </>
                                )}
                              </div>
                            );
                          })}

                          <div className="col-12 Upload_button mt-4">
                            <Button
                              type="submit"
                              disabled={Loading}
                              active={1}
                              br="50px"
                              p="10px"
                              id="btn"
                            >
                              {Loading ? (
                                <p
                                  style={{
                                    color: "snow",
                                    fontSize: "1.7rem",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItem: "center",
                                    marginTop: "3px",
                                  }}
                                >
                                  <i className="zmdi zmdi-rotate-left zmdi-hc-spin-reverse"></i>
                                </p>
                              ) : (
                                "Upload"
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </FormWrapper>
      </div>
    </>
  );
};

export default UploadUpcomingBook;
