import Cookie from "js-cookie";

export const getCookies = () => {
  const Token = Cookie.get("BhavyaPublication_Secret_Token");
  return Token;
};

export const blockInvalidChar = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

export const calculateSize = (img, maxWidth, maxHeight) => {
  let width = img.width;
  let height = img.height;

  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
  }
  return [width, height];
};

export const makeFileList = (files) => {
  const reducer = (dataTransfer, file) => {
    dataTransfer.items.add(file);
    return dataTransfer;
  };

  return files.reduce(reducer, new DataTransfer()).files;
};

export const ErrorMessageFun = (error, dispatch, setSnackbar) => {
  if (error.response.status === 500) {
    dispatch(setSnackbar(true, "error", error.response.data.message));
  } else if (error.response.status === 400) {
    dispatch(setSnackbar(true, "error", error.response.data.message));
  } else if (error.response.status === 409) {
    dispatch(setSnackbar(true, "error", error.response.data.message));
  } else if (error.response.status === 404) {
    dispatch(setSnackbar(true, "error", error.response.data.message));
  } else if (error.response.status === 401) {
    dispatch(setSnackbar(true, "error", error.response.data.message));
  } else if (error.response.status === 403) {
    dispatch(setSnackbar(true, "error", error.response.data.message));
  } else if (error.response.status === 405) {
    dispatch(setSnackbar(true, "error", error.response.data.message));
  } else if (error.response.status === 422) {
    dispatch(setSnackbar(true, "error", error.response.data.message));
  }
};
