import React from "react";
import { CgMenuLeftAlt } from "react-icons/cg";
import { Link } from "react-router-dom";
import { OpenLeftPanel } from "redux/actions/global";
import { useDispatch } from "react-redux";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BiLogOutCircle } from "react-icons/bi";
import { useAuth } from "middleware/AuthProvider";
import { RiAdminLine } from "react-icons/ri";


const Navbar = () => {
  const dispatch = useDispatch();
  const { Logout } = useAuth();

  return (
    <div className="RightPanel">
      <div className="container-fluid navbar_component">
        <div className="row">
          <div className="col-12 m-0 p-0">
            <nav className="navbar navbar-light">
              <CgMenuLeftAlt
                className="navbar-icon"
                onClick={() => dispatch(OpenLeftPanel())}
              />

              <Link to="/" className="navbar-brand ml-3">
                <img src="/images/Logo.png" alt="Loading..." />
              </Link>

              <ul className="navbar-nav left_ui_block ml-auto d-flex justify-content-center align-items-center flex-row">
                <li className="nav_list">
                  <div className="btn-group">
                    <button
                      type="button"
                      className="dropdown_btn d-flex align-items-center"
                      data-toggle="dropdown"
                      data-display="static"
                      aria-expanded="false"
                    >
                      <RiAdminLine className="bottom_icon" />
                      <MdKeyboardArrowDown className="bottom_icon ml-1" />
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">
                      <button
                        className="dropdown-item d-flex align-items-center"
                        type="button"
                        onClick={() => dispatch(Logout())}
                      >
                        <BiLogOutCircle className="profile_icon mr-2" />
                        <span>Logout</span>
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
