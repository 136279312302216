import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "components/Home";
import Layout from "components/globalComponents/Layout";
import Error from "components/globalComponents/Error";
import CustomizedSnackbar from "components/globalComponents/CustomizedSnackbar";
import SnackbarProviderMessage from "components/globalComponents/SnackbarProviderMessage";
import { useAuth } from "middleware/AuthProvider";
import Dashboard from "components/Dashboard";
import UploadUpcomingBook from "components/UploadComponents/UploadUpcomingBook";
import UploadBook from "components/UploadComponents/UploadBook";
import UploadEvent from "components/UploadComponents/UploadEvent";
import UploadPoster from "components/UploadComponents/UploadPoster";
import UploadPinCode from "components/UploadComponents/UploadPinCode";
import UploadTag from "components/UploadComponents/UploadTag";
import DeleteBook from "components/DeleteComponents/DeleteBook";
import DeleteUpcomingBook from "components/DeleteComponents/DeleteUpcomingBook";
import DeleteEvent from "components/DeleteComponents/DeleteEvent";
import DeletePoster from "components/DeleteComponents/DeletePoster";
import DeletePinCode from "components/DeleteComponents/DeletePinCode";
import DeleteTag from "components/DeleteComponents/DeleteTag";
import CurrentOrder from "components/Orders/CurrentOrder";
import PastOrder from "components/Orders/PastOrder";
import Order from "components/Orders/Order";
import { Loader } from "middleware/AuthProvider";

const App = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Loader>
      <SnackbarProviderMessage>
        <CustomizedSnackbar />
        {!isAuthenticated && (
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="*" element={<Error />} />
          </Routes>
        )}
        {isAuthenticated && (
          <Layout>
            <Routes>
              <Route exact path="/" element={<Dashboard />} />
              <Route exact path="/upload/book" element={<UploadBook />} />
              <Route exact path="/upload/event" element={<UploadEvent />} />
              <Route exact path="/upload/poster" element={<UploadPoster />} />
              <Route exact path="/upload/pincode" element={<UploadPinCode />} />
              <Route exact path="/upload/tag" element={<UploadTag />} />
              <Route exact path="/delete/books" element={<DeleteBook />} />
              <Route exact path="/delete/events" element={<DeleteEvent />} />
              <Route exact path="/delete/posters" element={<DeletePoster />} />
              <Route exact path="/delete/pincode" element={<DeletePinCode />} />
              <Route exact path="/delete/tag" element={<DeleteTag />} />
              <Route exact path="/orders/details" element={<Order />} />
              <Route
                exact
                path="/orders/current-orders"
                element={<CurrentOrder />}
              />
              <Route exact path="/orders/past-orders" element={<PastOrder />} />
              <Route
                exact
                path="/upload/upcomingBook"
                element={<UploadUpcomingBook />}
              />
              <Route
                exact
                path="/delete/upcomingBooks"
                element={<DeleteUpcomingBook />}
              />
              <Route path="*" element={<Error status={404} />} />
            </Routes>
          </Layout>
        )}
      </SnackbarProviderMessage>
    </Loader>
  );
};

export default App;
