import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import TitleToggle from "components/globalComponents/TitleToggle";
import Button from "Layout/Button";
import TableWrapper from "styles/global/table.style";
import { getAllPosterFun } from "utils/getMethodFunction";
import DataLoader from "components/globalComponents/Loaders/DataLoader";
import Error from "components/globalComponents/Error";
import { DeletePosterFun } from "utils/postMethodFunction";

const DeletePoster = () => {
  const dispatch = useDispatch();
  const [PosterList, setPosterList] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [Status, setStatus] = useState();

  useEffect(() => {
    const callApiFun = async () => {
      setLoading(true);
      const { poster, status } = await getAllPosterFun(setLoading);
      if (poster) {
        setPosterList(poster);
      }
      if (status) {
        setStatus(status);
      }
    };
    callApiFun();

    return () => {
      setPosterList([]);
      setStatus();
    };
  }, []);

  if (Loading) {
    return <DataLoader />;
  } else if (Status) {
    return <Error status={Status} />;
  }

  return (
    <>
      <TitleToggle Title="Poster" />
      <div className="RightPanel">
        <TableWrapper>
          <div className="container Delete">
            <div className="row d-flex justify-content-center table_section">
              <div className="col-lg-9 col-12">
                <div className="table_card">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Poster</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {PosterList &&
                        PosterList?.map((list) => {
                          return (
                            <tr key={list._id}>
                              <td>
                                <img
                                  src={list.laptop.Location}
                                  alt={list.laptop.key}
                                  loading="lazy"
                                />
                              </td>
                              <td>
                                <Button
                                  active={1}
                                  br="5px"
                                  p="0.5rem 0.5rem"
                                  id="btn"
                                  onClick={() =>
                                    dispatch(
                                      DeletePosterFun(
                                        list._id,
                                        [
                                          list.laptop.Location,
                                          list.mobile.Location,
                                        ],
                                        PosterList,
                                        setPosterList
                                      )
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </TableWrapper>
      </div>
    </>
  );
};

export default DeletePoster;
