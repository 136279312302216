import styled from "styled-components";

const OrderWrapper = styled.div`
  .OrderDetails {
    padding: 1rem 1.3rem;

    .order_id {
      span {
        font-weight: 500;
        font-size: 0.9rem;
      }
    }

    .OrderAddress_card {
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      padding: 1rem 2rem;
      transition: all 0.6s;
      border-radius: 10px;

      .address_title {
        p {
          font-weight: 500;
        }
      }

      .details {
        p,
        span {
          font-size: 0.9rem;
        }
      }

      .phone {
        p {
          font-weight: 500;
        }
        span {
          font-size: 0.9rem;
        }
      }
    }

    .status_details {
      #progressbar {
        overflow: hidden;
      }

      #progressbar li {
        list-style-type: none;
        font-size: 13px;
        width: 25%;
        float: left;
        position: relative;
        font-weight: 400;
        display: flex;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
      }

      #progressbar .step0 {
        .icon {
          width: auto;
          height: 1.2rem;
        }
        p {
          font-size: 0.7rem;
          margin: 2px 0;
        }
        span {
          font-weight: 500;
          font-size: 0.7rem;
        }
      }

      #progressbar .step0:before {
        font: "Material-Design-Iconic-Font";
        content: "\f26b";
        color: #fff;
      }

      #progressbar li:before {
        width: 15px;
        height: 15px;
        line-height: 45px;
        display: block;
        font-size: 20px;
        background: #c5cae9;
        border-radius: 50%;
        margin: auto;
        padding: 0px;
      }

      /*ProgressBar connectors*/
      #progressbar li:after {
        content: "";
        width: 100%;
        height: 4px;
        background: #c5cae9;
        position: absolute;
        left: 0;
        top: 5px;
        z-index: -1;
      }

      #progressbar li:last-child:after {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        position: absolute;
        left: -50%;
      }

      #progressbar li:nth-child(2):after,
      #progressbar li:nth-child(3):after {
        left: -50%;
      }

      #progressbar li:first-child:after {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        position: absolute;
        left: 50%;
      }

      #progressbar li:last-child:after {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      #progressbar li:first-child:after {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      /*Color number of the step and the connector before it*/
      #progressbar li.active:before,
      #progressbar li.active:after {
        background: ${(props) => props.theme.BodyPC_Main};
      }

      #progressbar li.active:before {
        content: "\f26b";
        font: "Material-Design-Iconic-Font";
        color: #fff;
      }
    }

    .order_list {
      padding: 0rem 1rem;

      .order_card {
        .img_section {
          img {
            width: auto;
            height: 4rem;
          }
        }

        .book_name {
          span {
            overflow: hidden;
            width: auto;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            font-size: 0.95rem;
          }
          .price {
            display: none;
          }
        }

        .price {
          display: flex;
          span {
            color: ${(props) => props.theme.BodyPC_Main};
            font-weight: 500;
            font-size: 0.9rem;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 770px) {
    .OrderDetails {
      padding: 1.5rem 0rem;

      .OrderAddress_card {
        padding: 1rem 1rem;
      }

      .order_list {
        .order_card {
          padding: 1rem;

          .book_name {
            .price {
              display: flex;
            }
          }

          .price {
            display: none;
          }
        }
      }
    }
  }
`;

export default OrderWrapper;
