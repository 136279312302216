import styled from "styled-components";

const ErrorWrapper = styled.div`
  .Error_Page {
    .Error {
      height: 85vh;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      img {
        max-width: 45%;
      }
    }
  }
`;

export default ErrorWrapper;
