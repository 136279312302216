import React, { useState } from "react";
import TitleToggle from "components/globalComponents/TitleToggle";
import Input from "Layout/Form/Input";
import FormWrapper from "styles/global/form.style";
import { UploadPosterFields } from "Layout/FormElement";
import Button from "Layout/Button";
import { UploadPosterFun } from "utils/postMethodFunction";
import { useDispatch } from "react-redux";
import { makeFileList } from "utils";

// var MAX_WIDTH = 1200;
// var MAX_HEIGHT = 500;
// var MAX_WIDTH1 = 400;
// var MAX_HEIGHT1 = 230;
var MIME_TYPE = "image/png";
var QUALITY = 0.7;

const UploadPoster = () => {
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);

  const [CreatePoster, setCreatePoster] = useState({
    LaptopImage: "",
    MobileImage: "",
  });

  const LaptopEventFormImg = (e) => {
    setCreatePoster({ ...CreatePoster, LaptopImage: e.target.files[0] });
  };

  const MobileEventFormImg = (e) => {
    setCreatePoster({ ...CreatePoster, MobileImage: e.target.files[0] });
  };

  const UploadEvent = async (event) => {
    event.preventDefault();

    const { LaptopImage, MobileImage } = CreatePoster;

    const blobURL = URL.createObjectURL(LaptopImage);
    const img = new Image();
    img.src = blobURL;
    img.onerror = function () {
      URL.revokeObjectURL(img.src);
      console.log("Cannot load image");
    };

    img.onload = function () {
      URL.revokeObjectURL(img.src);

      // const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
      const [newWidth, newHeight] = [img.width, img.height];

      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      canvas.toBlob(
        async (blob) => {
          const blobURL1 = URL.createObjectURL(MobileImage);
          const img1 = new Image();
          img1.src = blobURL1;
          img1.onerror = function () {
            URL.revokeObjectURL(img1.src);
            console.log("Cannot load image");
          };

          img1.onload = function () {
            URL.revokeObjectURL(img1.src);

            // const [newWidth1, newHeight1] = calculateSize(
            //   img1,
            //   MAX_WIDTH1,
            //   MAX_HEIGHT1
            // );
            const [newWidth1, newHeight1] = [img1.width, img1.height];

            const canvas1 = document.createElement("canvas");
            canvas1.width = newWidth1;
            canvas1.height = newHeight1;
            const ctx1 = canvas1.getContext("2d");
            ctx1.drawImage(img1, 0, 0, newWidth1, newHeight1);

            canvas1.toBlob(
              async (blob1) => {
                var file1 = new File([blob], LaptopImage.name, {
                  type: "image/png",
                });

                var file2 = new File([blob1], MobileImage.name, {
                  type: "image/png",
                });

                var newArr = makeFileList([file1, file2]);

                var formData = new FormData();

                for (var i = 0; i < newArr.length; i++) {
                  formData.append("images", newArr[i]);
                }

                dispatch(
                  UploadPosterFun(formData, setLoading, setCreatePoster)
                );
              },
              MIME_TYPE,
              QUALITY
            );
          };
        },
        MIME_TYPE,
        QUALITY
      );
    };
  };

  return (
    <>
      <TitleToggle Title="Poster" />
      <div className="RightPanel">
        <FormWrapper>
          <div className="container UploadPoster">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <form method="POST" onSubmit={UploadEvent}>
                  <div className="form_section">
                    <div className="row d-flex justify-content-center">
                      <div className="col-lg-6 col-10">
                        <div className="row">
                          {UploadPosterFields.map((List, ind) => {
                            const {
                              name,
                              type,
                              controlId,
                              required,
                              placeholder,
                            } = List;
                            return (
                              <div className="col-12 form_field" key={ind}>
                                {name === "OneImg" && (
                                  <label className="upload">
                                    <Input
                                      type={type}
                                      placeholder={placeholder}
                                      id={controlId}
                                      required={required}
                                      onChange={LaptopEventFormImg}
                                      autoComplete="off"
                                      accept="image/*"
                                      style={{ display: "none" }}
                                    />
                                    {CreatePoster?.LaptopImage?.name
                                      ? CreatePoster?.LaptopImage?.name
                                      : " Upload dashboard file size - 1200x500 "}
                                  </label>
                                )}
                                {name === "SecondImg" && (
                                  <label className="upload">
                                    <Input
                                      type={type}
                                      placeholder={placeholder}
                                      id={controlId}
                                      required={required}
                                      onChange={MobileEventFormImg}
                                      autoComplete="off"
                                      accept="image/*"
                                      style={{ display: "none" }}
                                    />
                                    {CreatePoster?.MobileImage?.name
                                      ? CreatePoster?.MobileImage?.name
                                      : " Upload mobile file size - 400 X 230"}
                                  </label>
                                )}
                              </div>
                            );
                          })}

                          <div className="col-12 Upload_button mt-4">
                            <Button
                              type="submit"
                              disabled={Loading}
                              active={1}
                              br="50px"
                              p="10px"
                              id="btn"
                            >
                              {Loading ? (
                                <p
                                  style={{
                                    color: "snow",
                                    fontSize: "1.7rem",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItem: "center",
                                    marginTop: "3px",
                                  }}
                                >
                                  <i className="zmdi zmdi-rotate-left zmdi-hc-spin-reverse"></i>
                                </p>
                              ) : (
                                "Upload"
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </FormWrapper>
      </div>
    </>
  );
};

export default UploadPoster;
