import React, { useEffect, useState } from "react";
import TitleToggle from "components/globalComponents/TitleToggle";
import Input from "Layout/Form/Input";
import Textarea from "Layout/Form/Textarea";
import Select from "Layout/Form/Select";
import FormWrapper from "styles/global/form.style";
import { UploadBookFields } from "Layout/FormElement";
import Button from "Layout/Button";
import {
  UploadBookFun,
  getAuthorsFun,
  getClassificationFun,
} from "utils/postMethodFunction";
import { useDispatch } from "react-redux";
import {  makeFileList } from "utils";

// var MAX_WIDTH = 112;
// var MAX_HEIGHT = 160;
var MIME_TYPE = "image/png";
var QUALITY = 1;

const UploadBook = () => {
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);
  const [authorList, setAuthorList] = useState([]);
  const [classificationList, setClassificationList] = useState([]);

  const [CreateBook, setCreateBook] = useState({
    name: "",
    price: "",
    author: "",
    classification: "",
    language: "",
    publishedYear: "",
    isbn: "",
    description: "",
    FontImage: "",
    BackImage: "",
  });

  const BookFormData = (e) => {
    setCreateBook({
      ...CreateBook,
      [e.target.name]: e.target.value,
    });
  };

  const FrontEventFormImg = (e) => {
    setCreateBook({ ...CreateBook, FontImage: e.target.files[0] });
  };

  const BackEventFormImg = (e) => {
    setCreateBook({ ...CreateBook, BackImage: e.target.files[0] });
  };

  const UploadUpcomingBook = (event) => {
    event.preventDefault();

    const {
      name,
      price,
      author,
      classification,
      language,
      publishedYear,
      isbn,
      description,
      FontImage,
      BackImage,
    } = CreateBook;

    const blobURL = URL.createObjectURL(FontImage);
    const img = new Image();
    img.src = blobURL;
    img.onerror = function () {
      URL.revokeObjectURL(img.src);
      console.log("Cannot load image");
    };

    img.onload = function () {
      URL.revokeObjectURL(img.src);

      // const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
      const [newWidth, newHeight] = [img.width, img.height];

      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      canvas.toBlob(
        async (blob) => {
          const blobURL1 = URL.createObjectURL(BackImage);
          const img1 = new Image();
          img1.src = blobURL1;
          img1.onerror = function () {
            URL.revokeObjectURL(img1.src);
            console.log("Cannot load image");
          };

          img1.onload = function () {
            URL.revokeObjectURL(img1.src);

            // const [newWidth1, newHeight1] = calculateSize(
            //   img1,
            //   MAX_WIDTH,
            //   MAX_HEIGHT
            // );
            const [newWidth1, newHeight1] = [img1.width, img1.height];
            const canvas1 = document.createElement("canvas");
            canvas1.width = newWidth1;
            canvas1.height = newHeight1;
            const ctx1 = canvas1.getContext("2d");
            ctx1.drawImage(img1, 0, 0, newWidth1, newHeight1);

            canvas1.toBlob(
              async (blob1) => {
                var file1 = new File([blob], FontImage.name, {
                  type: "image/png",
                });

                var file2 = new File([blob1], BackImage.name, {
                  type: "image/png",
                });

                var newArr = makeFileList([file1, file2]);

                var formData = new FormData();

                for (var i = 0; i < newArr.length; i++) {
                  formData.append("images", newArr[i]);
                }

                formData.append("name", name);
                formData.append("price", price);
                formData.append("author", author);
                formData.append("classification", classification);
                formData.append("language", language);
                formData.append("publishedYear", publishedYear);
                formData.append("isbn", isbn);
                formData.append("description", description);

                dispatch(UploadBookFun(formData, setLoading, setCreateBook));
              },
              MIME_TYPE,
              QUALITY
            );
          };
        },
        MIME_TYPE,
        QUALITY
      );
    };
  };

  useEffect(() => {
    const callAsync = async () => {
      await getAuthorsFun(setAuthorList);
      await getClassificationFun(setClassificationList);
    };
    callAsync();

    return () => {
      setAuthorList([]);
      setClassificationList([]);
    };
  }, []);

  return (
    <>
      <TitleToggle Title="Book" />
      <div className="RightPanel">
        <FormWrapper>
          <div className="container UploadBook">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <form method="POST" onSubmit={UploadUpcomingBook}>
                  <div className="form_section">
                    <div className="row d-flex justify-content-center">
                      <div className="col-lg-6 col-10">
                        <div className="row">
                          {UploadBookFields.map((List, ind) => {
                            const {
                              name,
                              type,
                              controlId,
                              required,
                              placeholder,
                            } = List;
                            return (
                              <div className="col-12 form_field" key={ind}>
                                {name === "name" ||
                                name === "price" ||
                                name === "language" ||
                                name === "publishedYear" ||
                                name === "isbn" ? (
                                  <Input
                                    type={type}
                                    name={name}
                                    placeholder={placeholder}
                                    id={controlId}
                                    onChange={BookFormData}
                                    required={required}
                                    value={CreateBook[name]}
                                    autoComplete="off"
                                  />
                                ) : null}

                                {name === "author" ||
                                name === "classification" ? (
                                  <Select
                                    name={name}
                                    required={required}
                                    value={CreateBook[name]}
                                    onChange={BookFormData}
                                    p="0.5rem"
                                    br="8px"
                                  >
                                    {name === "author" && (
                                      <>
                                        <option
                                          value=""
                                          defaultValue="selected"
                                          disabled
                                          hidden
                                        >
                                          Select author
                                        </option>
                                        {authorList?.map((item) => {
                                          return (
                                            <option
                                              value={item?.value}
                                              key={item?._id}
                                            >
                                              {item?.value}
                                            </option>
                                          );
                                        })}
                                      </>
                                    )}
                                    {name === "classification" && (
                                      <>
                                        <option
                                          value=""
                                          defaultValue="selected"
                                          disabled
                                          hidden
                                        >
                                          Select classification
                                        </option>
                                        {classificationList?.map((item) => {
                                          return (
                                            <option
                                              value={item?.value}
                                              key={item?._id}
                                            >
                                              {item?.value}
                                            </option>
                                          );
                                        })}
                                      </>
                                    )}
                                  </Select>
                                ) : null}

                                {name === "description" ? (
                                  <Textarea
                                    name={name}
                                    placeholder={placeholder}
                                    id={controlId}
                                    value={CreateBook[name]}
                                    onChange={BookFormData}
                                    required={required}
                                    rows="4"
                                    cols="50"
                                  />
                                ) : null}

                                {name === "OneImg" && (
                                  <label className="upload">
                                    <Input
                                      type={type}
                                      placeholder={placeholder}
                                      id={controlId}
                                      required={required}
                                      onChange={FrontEventFormImg}
                                      autoComplete="off"
                                      accept="image/*"
                                      style={{ display: "none" }}
                                    />
                                    {CreateBook?.FontImage?.name
                                      ? CreateBook?.FontImage?.name
                                      : " Upload font file"}
                                  </label>
                                )}
                                {name === "SecondImg" && (
                                  <label className="upload">
                                    <Input
                                      type={type}
                                      placeholder={placeholder}
                                      id={controlId}
                                      required={required}
                                      onChange={BackEventFormImg}
                                      autoComplete="off"
                                      accept="image/*"
                                      style={{ display: "none" }}
                                    />
                                    {CreateBook?.BackImage?.name
                                      ? CreateBook?.BackImage?.name
                                      : " Upload back file"}
                                  </label>
                                )}
                                {name === "OneImg" ||
                                  (name === "SecondImg" && (
                                    <p
                                      className="mt-2 ml-4"
                                      style={{ fontSize: "0.8rem" }}
                                    >
                                      Dimension should be 300x427
                                    </p>
                                  ))}
                              </div>
                            );
                          })}

                          <div className="col-12 Upload_button mt-4">
                            <Button
                              type="submit"
                              disabled={Loading}
                              active={1}
                              br="50px"
                              p="10px"
                              id="btn"
                            >
                              {Loading ? (
                                <p
                                  style={{
                                    color: "snow",
                                    fontSize: "1.7rem",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItem: "center",
                                    marginTop: "3px",
                                  }}
                                >
                                  <i className="zmdi zmdi-rotate-left zmdi-hc-spin-reverse"></i>
                                </p>
                              ) : (
                                "Upload"
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </FormWrapper>
      </div>
    </>
  );
};

export default UploadBook;
