import styled from "styled-components";

const HomeWrapper = styled.div`
  .LoginAdmin {
    min-height: calc(100vh - 205px - 80px);

    .from_tittle {
      h5 {
        font-size: 1.4rem;
      }
    }

    .LoginAdmin_form {
      form {
        width: 100%;

        .input_form {
          margin-top: 30px;
          position: relative;

          .icon1 {
            position: absolute;
            top: 13px;
            right: 50px;
            font-size: 1.2rem;
            cursor: pointer;
            color: ${(props) => props.theme.BodyPrimary};
          }

          input {
            border-radius: 50px;
            border: none;
            outline: none;
            padding: 10px;
            text-indent: 25px;
            width: 100%;
            color: ${(props) => props.theme.BodyPrimary};
            position: relative;
            border-radius: 50px;
            background-color: #f0f0f0;
          }

          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s;
            -webkit-text-fill-color: ${(props) =>
              props.theme.BodyPrimary} !important;
          }

          input::placeholder {
            color: ${(props) => props.theme.BodySecondary};
          }
        }

        .input_button {
          margin-top: 50px;
        }
      }
    }
  }

  .header {
    .logo_img {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: auto;
        height: 3rem;
      }
    }

    .title {
      h4 {
        font-size: 1.5rem;
      }
    }
  }
`;

export default HomeWrapper;
