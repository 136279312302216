import React from "react";
import LeftPanel from "./LeftPanel";
import Navbar from "./Navbar";
import HeaderWrapper from "./Header.style";

const Header = () => {
  return (
    <HeaderWrapper>
      <LeftPanel />
      <Navbar />
    </HeaderWrapper>
  );
};

export default Header;
