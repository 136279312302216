import React, { memo } from "react";

const Address = ({ receiverDetails }) => {
  return (
    <div className="col-lg-4 col-12">
      <div className="address_title">
        <p>Delivery Address</p>
      </div>
      <div className="details mt-1 d-flex flex-column">
        <p>{receiverDetails?.name}</p>
        <span>{receiverDetails?.address}</span>
        <span>
          {receiverDetails?.city}, {receiverDetails?.state}
        </span>
      </div>
      <div className="phone mt-2">
        <p>Phone number</p>
        <span>{receiverDetails?.phone}</span>
      </div>
    </div>
  );
};

export default memo(Address);
