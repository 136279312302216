import styled from "styled-components";

const CardWrapper = styled.div`
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  padding: ${(props) => props.p} 2rem;
  transition: all 0.6s;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 2px 3px ${(props) => props.theme.BodyBrand};
    outline-color: transparent;
    outline-width: 2px;
    outline-style: dotted;
  }

  &:hover .img_section img {
    transform: rotate(-5deg);
    transform: scale(1.1);
  }

  @media only screen and (max-width: 600px) {
    padding: ${(props) => props.p} 0.5rem;
  }
`;

export default CardWrapper;
