import React from "react";
import TitleToggle from "components/globalComponents/TitleToggle";
import DashboardWrapper from "./Dashboard.style";
import Button from "Layout/Button";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();

  return (
    <>
      <TitleToggle Title="Dashboard" />
      <div className="RightPanel">
        <DashboardWrapper>
          <div className="container Dashboard mt-lg-5 mt-3">
            <div className="row d-flex align-items-center">
              <div className="col-lg-5 col-12 d-flex justify-content-center align-items-center">
                <div className="details text-center d-flex justify-content-center flex-column align-items-center">
                  <p>Welcome to admin Panel</p>
                  <span>admin can access user order, Details</span>
                  <Button
                    id="btn"
                    active={1}
                    p="0.6rem 1rem"
                    br="50px"
                    className="mt-3"
                    onClick={() => navigate("/orders/current-orders")}
                  >
                    Check orders
                  </Button>
                </div>
              </div>
              <div className="col-lg-7 col-12  mt-lg-0 mt-4 d-flex justify-content-center">
                <div className="img_section d-flex justify-content-center">
                  <img
                    src="/images/Dashboard.png"
                    alt="Dashboard"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </DashboardWrapper>
      </div>
    </>
  );
};

export default Dashboard;
